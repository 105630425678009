import React from 'react'
import { useEffect } from 'react';
function Footer() {
    useEffect(() => {
        // Array of script URLs
        const scriptUrls = [
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/vendor/modernizr-3.6.0.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/vendor/jquery-3.6.0.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/vendor/jquery-migrate-3.3.2.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/vendor/bootstrap.bundle.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/aos.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/countdown.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/jquery.ajaxchimp.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/ion.rangeSlider.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/imagesloaded.pkgd.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/isotope.pkgd.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/parallax.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/Jarallax.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/masonry.pkgd.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/jquery.justifiedGallery.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/rellax.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/waypoints.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/jquery.animatedheadline.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/jquery.counterup.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/jquery.magnific-popup.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/jquery.selectric.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/sticky-sidebar.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/svg-inject.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/plugins/swiper.min.js",
          "https://cdn.jsdelivr.net/npm/vivus@0.4.4/dist/vivus.min.js",
          "https://htmldemo.net/maxcoach-v1/maxcoach/assets/js/main.js"
        ];
    
        // Function to load a single script
        const loadScript = (url) => {
          const script = document.createElement('script');
          script.src = url;
          script.async = true;
          document.body.appendChild(script);
          
          // Cleanup function to remove the script
          return () => {
            // document.body.removeChild(script);
             document.body.appendChild(script);
          };
        };
    
        // Load all scripts and get cleanup functions
        const cleanupFunctions = scriptUrls.map(loadScript);
    
        // Cleanup function to remove all scripts
        return () => {
        //   cleanupFunctions.forEach(cleanup => cleanup());
        };
      }, []);
  return (

    
     <div className="footer-section section" data-bg-color="#171621">
            <div className="container">

                {/* Footer Top Widgets Start */}
                <div className="row">

                    {/* Footer Widget Start */}
                    <div className="col-xl-6 col-md-5 col-12 max-mb-50">
                        <div className="footer-widget light-color">
                            <h4 className="footer-widget-title">Address</h4>
                            <div className="footer-widget-content">
                                <div className="content">
                                    <p>382 NE 191st St # 87394 Miami, FL 33179-3899</p>
                                    <p>+1 (305) 547-9909 (9am - 5pm EST, Monday - Friday) </p>
                                    <p><a href="/#">Obeddevhelp@gmail.com</a></p>
                                </div>
                                <div className="footer-social-inline">
                                    <a href="/#"><i className="fab fa-facebook-square"></i></a>
                                    <a href="/#"><i className="fab fa-twitter"></i></a>
                                    <a href="/#"><i className="fab fa-instagram"></i></a>
                                    <a href="/#"><i className="fab fa-linkedin"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Footer Widget End */}

                    {/* Footer Widget Start */}
                    <div className="col-xl-3 col-md-4 col-sm-7 col-12 max-mb-50">
                        <div className="footer-widget light-color">
                            <h4 className="footer-widget-title">Explore</h4>
                            <div className="footer-widget-content">
                                <ul className="column-2">
                                    <li><a href="/starthere">Start here</a></li>
                                    <li><a href="/success">Success story</a></li>
                                    <li><a href="/Blog">Blog</a></li>
                                    <li><a href="/Courses">Courses</a></li>
                                    <li><a href="/About">About us</a></li>
                                    <li><a href="/Contact">Contact us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Footer Widget End */}

                    {/* Footer Widget Start */}
                    <div className="col-xl-3 col-md-3 col-sm-5 col-12 max-mb-50">
                        <div className="footer-widget light-color">
                            <h4 className="footer-widget-title">Information</h4>
                            <div className="footer-widget-content">
                                <ul>
                                    <li><a href="/#">Membership</a></li>
                                    <li><a href="/#">Purchase guide</a></li>
                                    <li><a href="/#">Privacy policy</a></li>
                                    <li><a href="/#">Terms of service</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Footer Widget End */}

                </div>
                {/* Footer Top Widgets End */}

                {/* Footer Copyright Start */}
                <div className="row max-mt-20">
                    <div className="col">
                        <p className="copyright">&copy; 2024 Obeddev. <a href="/">All Rights Reserved</a></p>
                    </div>
                </div>
                {/* Footer Copyright End */}

            </div>

            
            
        </div>


            
       

  )
}

export default Footer