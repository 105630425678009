import React from 'react'

function Contact() {
  return (
    <>
  <div className="page-title-section section section-padding-top-0">
            <div className="page-breadcrumb position-static">
                <div class="container">
                    <ul className="breadcrumb">
                        <li><a href="/">Home</a></li>
                        <li className="current">Contact me</li>
                    </ul>
                </div>
            </div>
        </div>

    <div className="contact-information-section section section-padding-bottom">
            <div className="container">
              <div className="row">
                    <div className="col-lg-4">
                        <div className="contact-title">
                            <span className="sub-title">Get excited already?</span>
                            <h2 className="title">Reach me!</h2>
                        </div>

                        <div className="contact-info-wrap max-mb-n50">
                            {/* Single Contact Info Start */}
                            <div className="contact-info max-mb-50">
                                <div className="icon">
                                    <i className="fa fa-map-marker-alt"></i>
                                </div>
                                <div className="info">
                                    <h4 className="title"> Address</h4>
                                    <span className="info-text"> 1800 Abbot Kinney Blvd. Unit D & E Venice</span>
                                </div>
                            </div>
                            {/* Single Contact Info End */}

                            {/* Single Contact Info Start */}
                            <div className="contact-info max-mb-50">
                                <div className="icon">
                                    <i className="fa fa-phone"></i>
                                </div>
                                <div className="info">
                                    <h4 className="title"> Contact</h4>
                                    <span className="info-text"> Mobile: <strong>(+88) - 1990 - 6886</strong> 
                                        <br /> 
                                        Hotline: <strong>1800 – 1102</strong>
                                        <br />
                                        Mail: <a href="mailto:Obeddevhelp@gmail.com">Obeddevhelp@gmail.com</a>
                                    </span>
                                </div>
                            </div>
                            {/* Single Contact Info End */}

                            {/* Single Contact Info Start */}
                            <div className="contact-info max-mb-50">
                                <div className="icon">
                                    <i className="fa fa-clock"></i>
                                </div>
                                <div className="info">
                                    <h4 className="title"> Hour of operation</h4>
                                    <span className="info-text"> Monday – Friday : 09:00 – 20:00 <br/> Sunday &amp; Saturday: 10:30 – 22:00</span>
                                </div>
                            </div>
                            {/* Single Contact Info End */}
                        </div>

                    </div>
                    <div className="col-lg-8">
                        <div className="contact-image mt-sm-50 mt-xs-50">
                            <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/contact/contact.jpg" alt=""/>

                            {/* Animation Shape Start */}
                            <div className="shape shape-1 scene">
                                <span data-depth="3">
                                    <img src="assets/images/shape-animation/about-shape-1.png" alt=""/>
                                </span>
                            </div>
                            <div className="shape shape-2 scene">
                                <span data-depth="-3"><img src="assets/images/shape-animation/about-shape-1.png" alt=""/></span>
                            </div>
                            <div className="shape shape-3 scene">
                                <span data-depth="4">shape 3</span>
                            </div>
                            <div className="shape shape-4 scene">
                                <span data-depth="4"><img src="assets/images/shape-animation/shape-1.png" alt=""/></span>
                            </div>
                            <div className="shape shape-5 scene">
                                <span data-depth="4"><img src="assets/images/shape-animation/nwesletter-shape-2.png" alt=""/></span>
                            </div>
                            {/* Animation Shape End */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Contact Information Section End */}

        {/* Contact Form Section Start */}
        <div className="contact-form-section section section-padding-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="contact-title">
                            <h2 className="title">Or just drop me a line</h2>
                        </div>

                    </div>
                    <div className="col-lg-8">
                        <div className="contact-form pl-90 pl-md-0 pl-sm-0 pl-xs-0">
                            <form action="https://htmldemo.net/maxcoach-v1/maxcoach/assets/php/contact-mail.php" id="contact-form" method="post">
                                <div className="row max-mb-n30">
                                    <div className="col-md-6 col-12 max-mb-30">
                                        <input type="text" placeholder="Your Name *" name="name" />
                                    </div>
                                    <div className="col-md-6 col-12 max-mb-30">
                                        <input type="email" placeholder="Email *" name="email"/>
                                    </div>
                                    <div className="col-md-12 col-12 max-mb-30">
                                        <input type="text" placeholder="Subject *" name="subject"/>
                                    </div>
                                    <div className="col-12 max-mb-30">
                                        <textarea name="message" placeholder="Message"></textarea>
                                    </div>
                                    <div className="col-12 text-left max-mb-30">
                                        <button className="btn btn-primary btn-hover-secondary btn-width-180 btn-height-60">Submit</button>
                                    </div>
                                </div>
                            </form>
                            <p className="form-messege"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Contact Form Section End */}
    
    </>
  )
}

export default Contact