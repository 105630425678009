import React from 'react'
import Records from "../components/Posts.json"
import { Link } from 'react-router-dom'
function Blog() {
    const handleProductClick = () => {
        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    
        
      };
  return (
    <>

<div className="page-title-section section">
            <div className="page-title">
                <div className="container">
                    <h1 className="title">Blog Masonry</h1>
                </div>
            </div>
            <div className="page-breadcrumb">
                <div className="container">
                    <ul className="breadcrumb">
                        <li><a href="/">Home</a></li>
                        <li className="current">Blog Masonry</li>
                    </ul>
                </div>
            </div>
        </div>
        {/* Page Title Section End */}

        {/* Blog Section Start */}
        <div className="section section-padding-bottom">
            <div className="container">

                {/* Blog Wrapper Start */}
                <div className="isotope-grid row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">

                    <div className="grid-sizer col-1"></div>

                    {/* Blog Start */}
                    {Records && Records.map((Record) =>{
                        return(
                        <div className="grid-item col max-mb-30" data-aos="fade-up" key={Record.id}>
                        <div className="blog-2" onClick={handleProductClick}>
                            <div className="thumbnail">
                              <Link to={`/blog/${Record.id}`} > <a href="/post" className="image"><img src={Record.thumbnail} alt="Blog Imae"/></a> </Link>
                            </div>
                            <div className="info">
                              <a href={`/blog/${Record.id}`} className="category">{Record.category}</a>
                                <h3 className="title"><Link to={`/blog/${Record.id}`} ><a href="/blog">{Record.title}</a></Link></h3>
                                <ul className="meta">
                                    <li><i className="fas fa-calendar"></i>{Record.month} 0{Record.day}, {Record.year}</li>
                                    <li><i className="fas fa-eye"></i>{Record.views} views</li>
                                </ul>
                            </div>
                        </div>
                    </div>)
                    })}
                    

                </div>
                {/* Blog Wrapper End */}

                {/* Pagination Start */}
                <div className="row max-mt-50">
                    <div className="col">

                        <ul className="pagination center">
                            <li><a href="/" className="prev">PREV</a></li>
                            <li><a href="/" className="active">1</a></li>
                            <li><a href="/">2</a></li>
                            <li><a href="/" className="next">NEXT</a></li>
                        </ul>

                    </div>
                </div>
                {/* Pagination End */}

            </div>
        </div>

    </>
  )
}

export default Blog