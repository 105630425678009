import React from 'react'


function About() {
  return (
    <>
      
      <div className="page-title-section section section-padding-top-0" data-bg-color="#f5f5f5">
            <div className="page-breadcrumb position-static">
                <div className="container">
                    <ul className="breadcrumb">
                        <li><a href="/">Home</a></li>
                        <li className="current">About us</li>
                    </ul>
                </div>
            </div>
        </div>
        {/* Page Title Section End */}

        {/* Testimonial Section Start */}
        <div className="testimonial-section section" data-bg-color="#f5f5f5">
            <div className="container animation-shape">
                <div className="row">
                    <div className="col-lg-12">
                        {/*Testimonial Slider Start */}
                        <div className="testimonial-slider-three swiper-container" data-aos="fade-up">
                            <div className="swiper-wrapper">

                                <div className="swiper-slide">
                                    <div className="testimonial-four">
                                        <div className="content">
                                            <h3 className="title">Let passion and determination be the guide along the way and develop at your own pace that's comfortable.</h3>
                                        </div>
                                        <div className="author-info">
                                            <div className="cite">
                                                <h6 className="name">Jim Carreyen</h6>
                                                <span className="position">/ Multimedia Admin</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-four">
                                        <div className="content">
                                            <h3 className="title">Let passion and determination be the guide along the way and develop at your own pace that's comfortable.</h3>
                                        </div>
                                        <div className="author-info">
                                            <div className="cite">
                                                <h6 className="name">Austin Beerman</h6>
                                                <span className="position">/ Admin</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-four">
                                        <div className="content">
                                            <h3 className="title">Let passion and determination be the guide along the way and develop at your own pace that's comfortable.</h3>
                                        </div>
                                        <div className="author-info">
                                            <div className="cite">
                                                <h6 className="name">Florence Jason</h6>
                                                <span className="position">/  Programmer</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-four">
                                        <div className="content">
                                            <h3 className="title">Let passion and determination be the guide along the way and develop at your own pace that's comfortable.</h3>
                                        </div>
                                        <div className="author-info">
                                            <div className="cite">
                                                <h6 className="name">Florence Kate</h6>
                                                <span className="position">/ Obeddev Admin</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-pagination"></div>

                            </div>
                        </div>
                        {/*Testimonial Slider End */}
                    </div>
                </div>
                <div className="shape shape-1 scene">
                    <span data-depth="4">shape 1</span>
                </div>
                <div className="shape shape-2 scene">
                    <span data-depth="4"></span>
                </div>
                <div className="shape shape-3 scene">
                    <span data-depth="4"></span>
                </div>
                <div className="shape shape-4 scene">
                    <span data-depth="4"></span>
                </div>
                <div className="shape shape-5 scene">
                    <span data-depth="4"></span>
                </div>
                <div className="shape shape-6 scene">
                    <span data-depth="4"></span>
                </div>
                
            </div>
        </div>







      <div className="teaching-section section section-padding-top section-padding-bottom-70" data-bg-color="#f5f5f5">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-8">
                        <div className="testimonial-area mb-sm-30 mb-xs-30">
                            <div className="row">
                                <div className="col-md-6">
                                    {/* Static Testimonial Start */}
                                    <div className="static-testimonial">
                                        <div className="testimonial-image">
                                            <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/testimonial/140/home-4-testimonial-avatar-01.png" alt=""/>
                                        </div>
                                        <div className="testimonial-content">
                                            <p>I love their flexibility. Even when my request is too complicated to handle. they could still suggest something useful for me. </p>
                                        </div>
                                        <div className="author-info">
                                            <div className="cite">
                                                <h6 className="name">Abigael Noami</h6>
                                                <span className="position">/ Multimedia Admin</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Static Testimonial End */}
                                    <div className="testimonial-modern-heading">
                                        <h2 className="title">"Best out of the best in the online coaching field ..."</h2>
                                    </div>
                                </div>
                                <div className="col-md-6 pr-100 pr-md-15 pr-sm-15 pr-xs-15">
                                    <div className="testimonial-rating-area">
                                        <div className="rating-number">
                                            <h2>4.9/5.0</h2>
                                        </div>
                                        <div className="testimonial-rating">
                                            <span className="fa fa-star"></span>
                                            <span className="fa fa-star"></span>
                                            <span className="fa fa-star"></span>
                                            <span className="fa fa-star"></span>
                                            <span className="fa fa-star"></span>
                                        </div>
                                        <div className="rating-content">
                                            <p>by 700+ Students for 3200+ Sales</p>
                                        </div>
                                    </div>
                                    {/* Static Testimonial Start */}
                                    <div className="static-testimonial">
                                        <div className="testimonial-image">
                                            <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/testimonial/100/home-4-testimonial-avatar-02.png" alt=""/>
                                        </div>
                                        <div className="testimonial-content">
                                            <p>Excellent guiding instructions. They are all great mentors at life & career </p>
                                        </div>
                                        <div className="author-info">
                                            <div className="cite">
                                                <h6 className="name">Fannie Moreno</h6>
                                                <span className="position">/ Developer</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Static Testimonial End */}
                                </div>
                            </div>

                            {/* Animation Shape Start */}
                            <div className="shape shape-1 scene">
                                <span data-depth="3">
                                    <img src="assets/images/shape-animation/shape-2.svg" alt="" className="svgInject"/>
                                </span>
                            </div>
                            <div className="shape shape-2 scene">
                                <span data-depth="-3"><img src="assets/images/shape-animation/shape-3.png" alt=""/></span>
                            </div>
                            <div className="shape shape-3 scene">
                                <span data-depth="4">shape 3</span>
                            </div>
                            <div className="shape shape-4 scene">
                                <span data-depth="4"><img src="assets/images/shape-animation/nwesletter-shape-2.png" alt=""/></span>
                            </div>
                            {/* Animation Shape End */}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="teaching-content">
                            {/* Section Title Start */}
                            <div className="section-title text-left mb-15" data-aos="fade-up">
                                <span className="sub-title">Together expand and grow</span>
                                <h2 className="title">Students Enjoy <span>Our Companionship</span> in Teaching.</h2>
                            </div>
                            {/* Section Title End */}
                            <p>As learners, people can enjoy great companionship from MaxCoach mentors and educators. We can help you develop and grow at your best.</p>
                            <a href="/" className="btn btn-primary btn-hover-secondary max-mt-30">Get trial courses</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        
    </>
  )
}

export default About