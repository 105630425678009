import React, { useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import Jrecords from "../components/Posts.json"
import { Link } from 'react-router-dom'
function PostDetails() {
    const {postId} =useParams()

    const foundRecord = Jrecords.find(item => item.id == postId);
   
    const [posts, setPosts] = useState([]);
    const [paginate , setPaginate] = useState([])
    const [paginatel , setPaginatel] = useState([])

  useEffect(() => {
        setPosts(Jrecords.slice(0, 6));

        
            const randomIndex = Math.floor(Math.random() * Jrecords.length);
            setPaginate(Jrecords[randomIndex]);
            setPaginatel(Jrecords[Math.floor(Math.random() * Jrecords.length)]);
          
          
    }, []);
    console.log(paginate)
    const handleProductClick = () => {
        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    
        
      };
  return (
    <> 
        <div className="page-title-section section">
            <div className="page-title">
                <div className="container">
                    <h1 className="title">Blog Details</h1>
                </div>
            </div>
            <div className="page-breadcrumb">
                <div className="container">
                    <ul className="breadcrumb">
                        <li><a href="/">Home</a></li>
                        <li className="current">Blog Details</li>
                    </ul>
                </div>
            </div>
        </div>
        {/* Page Title Section End */}

        {/* Blog Section Start */}
        <div className="section section-padding-bottom fix">
            <div className="container">
                <div className="row max-mb-n50">

                    <div className="col-lg-8 col-12 order-lg-2 max-mb-50">
                        {/* Blog Wrapper Start */}
                        <div className="row row-cols-1 g-0">

                            {/* Blog Start */}
                            <div className="blog-3 blog-details col" data-aos="fade-up" key={foundRecord.id}>
                                <div className="thumbnail">
                                    <img src={foundRecord.Bthumbnail} alt="Blog Imge"/>
                                </div>
                                <div className="info">
                                    <a href="/#" className="category">{foundRecord.category}</a>
                                    <h3 className="title">{foundRecord.title}</h3>
                                    <ul className="meta">
                                        <li><a href="/#"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/blog/blog-avatar.jpg" alt="" className="avatar"/>Owen Christ</a></li>
                                        <li><i className="fas fa-calendar"></i>{foundRecord.month} 0{foundRecord.day}, {foundRecord.year}</li>
                                        <li><i className="fas fa-eye"></i>{foundRecord.views} views</li>
                                    </ul>
                                    <div className="desc">
                                        <p>Paging through a photo album detailing every moment of a friend’s poolside vacation might not be a riveting activity, but flipping through Angela Mckay’s sketchbooks filled with tiny paintings of her travels certainly is. The Brooklyn-based pattern designer and illustrator of&nbsp;<a href="/#">Ohkii Studio</a>&nbsp;documents the lush scenery, cavernous waters, and hilly villages she sees on the streets of Lagos, Calamosche Beach on Italy’s southern coast, and in Joshua Tree National Park. Mckay generally positions her miniature paintings against the real-life backdrop, juxtaposing the two depictions that she then shares on&nbsp;<a href="/#">Instagram</a>.</p>

                                        <p>The artist tells Colossal that she frequently recreates some of the pieces in her sketchbooks on a larger scale after returning home, relying on her earlier representation for the tiny details she otherwise might not remember. “Often when I’m traveling, I have this urgent feeling that I need to capture everything I’m experiencing, the sights, feelings and textures of a place,” she says.</p>

                                        <blockquote className="block-quote">
                                            <p>I really enjoy that feeling of walking around a new place not knowing what I might discover around the corner. I often try to recreate the feeling of a place I have visited in my personal work… I really enjoy the experience of looking at a painting and being transported back to that experience. It’s a nice way to escape from your day to day!</p>
                                        </blockquote>

                                        <p>The pocket-size notebooks are a crucial component of Mckay’s process, and she utilizes them in both her personal projects and her work for clients. “They just allow me to play with ideas and explore other directions without having to commit to anything,” she says. To pick up one of Mckay’s watercolor and gouache&nbsp;<a href="/#">artworks</a><a href="/#">prints</a>, head to her shop. (via&nbsp;<a href="/#">Lustik</a>)</p>

                                        <p>Source: thisiscolossal.com</p>
                                    </div>

                                    <div className="row justify-content-between align-items-center max-mt-50">
                                        <div className="col-auto">
                                            <div className="entry-post-tags">
                                                <div className="tag-label">
                                                    <span className="tag-icon fas fa-tags"></span>
                                                </div>
                                                <div className="tag">
                                                    <a href="/#">artist, </a>
                                                    <a href="/#">education</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="post-share">
                                                <span className="label">Share this post</span>
                                                <div className="media">
                                                    <span className="icon"><i className="fas fa-share-alt"></i></span>
                                                    <div className="list">
                                                        <a href="/#"><i className="fab fa-facebook-f"></i></a>
                                                        <a href="/#"><i className="fab fa-twitter"></i></a>
                                                        <a href="/#"><i className="fab fa-linkedin"></i></a>
                                                        <a href="/#"><i className="fab fa-tumblr-square"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* Blog End */}

                            <div className="entry-author">
                                <div className="author-info">
                                    <div className="author-avatar">
                                        <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/author/blog-author.jpg" alt=""/>
                                        <div className="author-social-networks">
                                            <div className="inner">
                                                <a className="hint--bounce hint--top hint--primary" aria-label="Twitter" href="/#" target="_blank">
                                                    <i className="fab fa-twitter"></i>
                                                </a>

                                                <a className="hint--bounce hint--top hint--primary" aria-label="Facebook" href="/#" target="_blank">
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>

                                                <a className="hint--bounce hint--top hint--primary" aria-label="Instagram" href="/#" target="_blank">
                                                    <i className="fab fa-instagram"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="author-description">
                                        <h6 className="author-name">Owen Christ</h6>

                                        <div className="author-biographical-info">
                                            Owen Christ is an author, blogger, and designer living in a suburb of Washington, DC. When she’s not designing, blogging, or writing, Owen can be found with her head in a book or pinning like a madman.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="blog-nav-links">
                                <div className="nav-list">
                                    <div className="nav-item prev">
                                        <div className="inner" onClick={handleProductClick}>
                                        <Link to={`/blog/${paginatel.id}`} >    <a href="/blog">
                                                <div className="hover-bg has-thumbnail" data-bg-image={paginatel.Bthumbnail}></div>
                                                <h6>{paginatel.title}</h6>
                                            </a>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="nav-item next">
                                        <div className="inner" onClick={handleProductClick}>
                                            
                                        <Link to={`/blog/${paginate.id}`} >   <a href="/blog">
                                                <div className="hover-bg has-thumbnail" data-bg-image={paginate.Bthumbnail}></div>
                                                <h6>{paginate.title}</h6>
                                            </a>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="comment-list-wrapper">

                                <div className="row">
                                    <div className="col-lg-12">
                                        <h4 className="title">Comments <span>(3)</span> </h4>
                                    </div>
                                    <div className="col-lg-12">

                                        <ol className="comment-list">
                                            <li className="comment border-bottom">
                                                <div className="comment-2">
                                                    <div className="comment-author vcard">
                                                        <img alt="" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/comment/comment1.jpeg"/>
                                                    </div>
                                                    <div className="comment-content">
                                                        <div className="meta">
                                                            <h6 className="fn">Edna Watson</h6>
                                                        </div>
                                                        <div className="comment-text">
                                                            <p>Thanks for always keeping your HTML Template up to date. Your level of support and dedication is second to none.</p>
                                                        </div>

                                                        <div className="comment-actions">
                                                            <div className="comment-datetime"> November 16, 2018 at 4:31 am </div><span> | </span>
                                                            <a className="comment-reply-link" href="/#">Reply</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>{/* comment End*/}
                                            <li className="comment border-bottom">
                                                <div className="comment-2">
                                                    <div className="comment-author vcard">
                                                        <img alt="" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/comment/comment2.jpeg"/>
                                                    </div>
                                                    <div className="comment-content">
                                                        <div className="meta">
                                                            <h6 className="fn">Owen Christ</h6>
                                                        </div>
                                                        <div className="comment-text">
                                                            <p>Thanks for always keeping your HTML Template up to date. Your level of support and dedication is second to none.</p>
                                                        </div>

                                                        <div className="comment-actions">
                                                            <div className="comment-datetime"> November 19, 2018 at 4:31 am </div><span> | </span>
                                                            <a className="comment-reply-link" href="/#">Reply</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>{/* comment End*/}
                                            <li className="comment">
                                                <div className="comment-5">
                                                    <div className="comment-author vcard">
                                                        <img alt="" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/comment/comment1.jpeg"/>
                                                    </div>
                                                    <div className="comment-content">
                                                        <div className="meta">
                                                            <h6 className="fn">James Scott</h6>
                                                            <div className="comment-datetime">
                                                                November 13, 2018 at 4:50 am </div>
                                                        </div>
                                                        <div className="comment-text">
                                                            <p>Thanks for always keeping your HTML Template up to date. Your level of support and dedication is second to none.</p>
                                                        </div>

                                                        <div className="comment-actions">
                                                            <div className="comment-datetime"> February 12, 2018 at 6:31 am </div><span> | </span>
                                                            <a className="comment-reply-link" href="/#">Reply</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ol className="children">
                                                    <li className="comment ">
                                                        <div className="comment-6">
                                                            <div className="comment-author vcard">
                                                                <img alt="" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/comment/comment3.jpeg"/>
                                                            </div>
                                                            <div className="comment-content">
                                                                <div className="meta">
                                                                    <h6 className="fn">Harry Ferguson</h6>
                                                                    <div className="comment-datetime">
                                                                        February 13, 2019 at 4:51 am </div>
                                                                </div>
                                                                <div className="comment-text">
                                                                    <p>Thanks for always keeping your HTML Template up to date. Your level of support and dedication is second to none.</p>
                                                                </div>

                                                                <div className="comment-actions">
                                                                    <div className="comment-datetime"> November 13, 2018 at 4:31 am </div><span> | </span>
                                                                    <a className="comment-reply-link" href="/#">Reply</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>{/* comment End */}
                                                </ol>{/* children End */}
                                            </li>{/* comment End*/}
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="comment-form-wrap">
                                <div className="comment-respond">
                                    <h3 className="title">Leave your thought here </h3>
                                    <p className="comment-notes"><span id="email-notes">Your email address will not be published.</span> Required fields are marked <span className="required">*</span></p>
                                    <form action="#" method="post">
                                        <div className="row">
                                            <div className="col-md-6 col-12 max-mb-30">
                                                <input type="text" placeholder="Your Name *" name="name"/>
                                            </div>
                                            <div className="col-md-6 col-12 max-mb-30">
                                                <input type="email" placeholder="Email *" name="email"/>
                                            </div>
                                            <div className="col-12 max-mb-30">
                                                <textarea name="message" placeholder="Your Comment"></textarea>
                                            </div>
                                            <div className="col-12">
                                                <p className="comment-form-cookies-consent justify-content-start text-left">
                                                    <input id="comment-cookies-consent" name="comment-cookies-consent" type="checkbox" value="yes"/>
                                                    <label for="comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                                                </p>
                                            </div>
                                            <div className="col-12 text-left">
                                                <button className="btn btn-primary btn-hover-secondary btn-width-180 btn-height-60">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                        {/* Blog Wrapper End */}
                    </div>

                    <div className="col-lg-4 col-12 order-lg-1 max-mb-50">
                        <div className="sidebar-widget-wrapper">
                            <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title">Search</h3>
                                <div className="sidebar-widget-content">
                                    <div className="sidebar-widget-search">
                                        <form action="#">
                                            <input type="text" placeholder="Search..."/>
                                            <button><i className="fas fa-search"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title mb-2">Popular posts</h3>
                                <div className="sidebar-widget-content">
                                    

                                   
                                    <ul className="sidebar-widget-list-post">
                                        {posts.map(post=>(
                                           <Link to={`/blog/${post.id}`} > <li onClick={handleProductClick}>
                                                <a href="/post">{post.title}</a>
                                              
                                              </li></Link>
                                        ))}
                                         
                                    </ul> 
                               
                                </div>
                            </div>
                            <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title">Popular tags</h3>
                                <div className="sidebar-widget-content">
                                    <div className="tagcloud">
                                        <a href="/#">course</a>
                                        <a href="/#">education</a>
                                        <a href="/#">general</a>
                                        <a href="/#">gym coaching</a>
                                        <a href="/#">health coaching</a>
                                        <a href="/#">learning</a>
                                        <a href="/#">methodology</a>
                                        <a href="/#">online</a>
                                        <a href="/#">pinterest</a>
                                        <a href="/#">strategies</a>
                                        <a href="/#">teaching</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        {/* Blog Section End */}
    </>
  )
}

export default PostDetails