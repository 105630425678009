import React from 'react'
import shopproducts from '../components/Shopsec/shopD.json'
import { Link} from 'react-router-dom'


function Shop() {
    // const {productId} = useParams()
    
    const handleProductClick = () => {
        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    
        
      };
  return (
    <>
    
    <div className="page-title-section section">
            <div className="page-title">
                <div className="container">
                    <h1 className="title">Shop</h1>
                </div>
            </div>
            <div className="page-breadcrumb">
                <div className="container">
                    <ul className="breadcrumb">
                        <li><a href="/">Home</a></li>
                        <li className="current">Shop</li>
                    </ul>
                </div>
            </div>
        </div>
        {/* Page Title Section End */}

        {/* Shop Product Section Start */}
        <div className="section section-padding-bottom">
            <div className="container">
                <div className="row max-mb-n50">

                    <div className="col-xl-9 col-lg-8 col-12 max-mb-50">

                        {/* Product Wrapper Start */}
                        <div className="row row-cols-xl-3 row-cols-sm-2 row-cols-1 max-mb-n40">
                        {shopproducts.map((shopproduct) => {
                        return (
                            <div className="col max-mb-40" data-aos="fade-up" key={shopproduct.id} >
                            <div className="product" onClick={handleProductClick}>
                                <div className="thumbnail">
                                <Link to={`product/${shopproduct.id}`} >   <a href="/product" className="image"><img src={shopproduct.image} height={177} alt=""/></a></Link>
                                    <div className="actions">
                                        <a href="/quick-view-modal-container" data-toggle="modal" className="action hintT-left hintT-primary" data-hint="Quick view"><i className="fas fa-search"></i></a>
                                        <a href="shopping-cart.html" className="action hintT-left hintT-primary" data-hint="Add to cart"><i className="fas fa-shopping-basket"></i></a>
                                        <a href="/#" className="action hintT-left hintT-primary" data-hint="Add to wishlist"><i className="fas fa-heart"></i></a>
                                        <a href="/#" className="action hintT-left hintT-primary" data-hint="Compare"><i className="fas fa-signal"></i></a>
                                    </div>
                                </div>
                                <div className="info">
                                    <h3 className="title"><a href="/product-details">{shopproduct.Title}</a></h3>
                                    <span className="price">${shopproduct.price}</span>
                                </div>
                            </div>
                        </div>
                        
                        )
                       })} 
                       
                       </div> 
                        
                        {/* Product Wrapper End */}

                        {/* Pagination Start */}
                        <div className="row max-mt-50">
                            <div className="col">

                                <ul className="pagination center">
                                    <li><a href="/" className="prev">PREV</a></li>
                                    <li><a href="/" className="active">1</a></li>
                                    <li><a href="/">2</a></li>
                                    <li><a href="/" className="next">NEXT</a></li>
                                </ul>

                            </div>
                        </div>
                        {/* Pagination End */}
                    </div>

                    {/* Sidebar Widget Wrapper Start */}
                    <div className="col-xl-3 col-lg-4 col-12 max-mb-50">
                        <div className="sidebar-widget-wrapper pr-0">

                            {/* Widget Search Start */}
                            <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title">Search</h3>
                                <div className="sidebar-widget-content">
                                    <div className="sidebar-widget-search">
                                        <form action="#">
                                            <input type="text" placeholder="Search..."/>
                                            <button><i className="fas fa-search"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/* Widget Search End */}

                            {/* Widget Price Filter Start */}
                            <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title">Filter by price</h3>
                                <div className="sidebar-widget-content">
                                    <div className="sidebar-widget-price-filter">
                                        <div className="price-range" data-min="0" data-max="100" data-from="0" data-to="100"></div>
                                        <button className="btn btn-primary btn-hover-secondary btn-price-filter">Filter</button>
                                    </div>
                                </div>
                            </div>
                            {/* Widget Price Filter End */}

                            {/* Widget Product Start */}
                            <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title">Popular products</h3>
                                <div className="sidebar-widget-content">
                                    <div className="sidebar-widget-products">
                                        <div className="widget-product">
                                            <a href="/product-details" className="thumbnail"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/products/widget/product-1.jpg" alt=""/></a>
                                            <div className="info">
                                                <h6 className="title"><a href="/product-details">Use SEO to Boost Your Business</a></h6>
                                                <span className="rating"><span></span></span>
                                                <span className="price">$49.00 <span className="old">$69.00</span></span>
                                            </div>
                                        </div>
                                        <div className="widget-product">
                                            <a href="/product-details" className="thumbnail"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/products/widget/product-2.jpg" alt=""/></a>
                                            <div className="info">
                                                <h6 className="title"><a href="/product-details">The Evolution of the Digital World</a></h6>
                                                <span className="price">$69.00</span>
                                            </div>
                                        </div>
                                        <div className="widget-product">
                                            <a href="/product-details" className="thumbnail"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/products/widget/product-3.jpg" alt=""/></a>
                                            <div className="info">
                                                <h6 className="title"><a href="/product-details">The Source of Learning and Development</a></h6>
                                                <span className="price">$99.00</span>
                                            </div>
                                        </div>
                                        <div className="widget-product">
                                            <a href="/product-details" className="thumbnail"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/products/widget/product-4.jpg" alt=""/></a>
                                            <div className="info">
                                                <h6 className="title"><a href="/product-details">Early Field Experience: Teach Well</a></h6>
                                                <span className="price">$59.00</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Widget Product End */}

                            {/* Widget Tags Start */}
                            <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title">Popular tags</h3>
                                <div className="sidebar-widget-content">
                                    <div className="tagcloud">
                                        <a href="/">business</a>
                                        <a href="/">business administration</a>
                                        <a href="/">computer</a>
                                        <a href="/">Computer science</a>
                                        <a href="/">development</a>
                                        <a href="/">digital</a>
                                        <a href="/">early education</a>
                                        <a href="/">learning</a>
                                        <a href="/">marketing</a>
                                        <a href="/">online learning</a>
                                        <a href="/">programming</a>
                                        <a href="/">seo</a>
                                        <a href="/">software</a>
                                        <a href="/">teaching</a>
                                        <a href="/">website</a>
                                    </div>
                                </div>
                            </div>
                            {/* Widget Tags End */}

                        </div>
                    </div>
                    {/* Sidebar Widget Wrapper End */}

                </div>
            </div>
        </div>

    </>
  )
}

export default Shop