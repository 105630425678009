import React, {useEffect, useState} from 'react'
import courseparams from "./components/course.json"
import { Link } from 'react-router-dom';


function Hompage() {
    const [filteredData, setFilteredData] = useState([]);
     const [product, setProduct] = useState([]);  


     const handleProductClick = () => {
        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    
        
      };

        useEffect(() => {
          // Filter the data based on the category 'fruit'
          const filtered = courseparams.filter(item => item.category === 'hpage');
      
          // Slice the data to get only the first 3 items
          const sliced = filtered.slice(0, 3);
      
          // Update the state with the filtered and sliced data
          setFilteredData(sliced);
        }, []);
       

  useEffect(() => {
    // Locate the product based on category and color
    const foundProduct = courseparams.filter(item => item.category === "programming" && item.price === "Free");

    // Update the state with the found product
    setProduct(foundProduct);
  }, []);
  return (
    <>
        

        {/* Slider/Intro Section Start */}
        <div className="intro1-section section section-fluid" data-bg-image="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/intro/intro1/bg-1.jpg">

            <div className="container">
                <div className="row row-cols-md-2 row-cols-1 max-mb-n30">

                    {/* Intro One Content Start */}
                    <div className="col align-self-center max-mb-30" data-aos="fade-up">
                        <div className="intro1-content text-center text-md-start">
                            <span className="sub-title" data-aos="fade-left"><span style={{color: 'green'}}><span style={{fontSize:24, fontWeight:800}}>O</span>bed</span>Dev is the lifebuoy</span>
                            <h2 className="title">In Mentoring <br/>2B a Better You.</h2>
                            <div className="desc">
                                <p>The right mentoring relationship can be a powerful tool for professional growth. Bark up the right tree.</p>
                            </div>
                            <a href="/" className="btn btn-primary btn-hover-secondary"><i className="fas fa-download me-3"></i> Download free guide</a>
                        </div>
                    </div>
                    {/* Intro One Content End */}

                    {/* Intro One Course Start */}
                    <div className="col max-mb-30" data-aos="fade-up">
                        <div className="intro1-course">

                            <img className="popular-course-icon" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/intro/intro1/intro-popular-course.png" alt=""/>

                            {/* Course Start */}
                            {product.map(item => (

                           
                            <div className="course-2" onClick={handleProductClick} key={item.id}>
                                <div className="thumbnail">
                                <Link to={`/courses/course/${item.id}`} >    <a href="/course/course" className="image"><img src={item.imagec} alt="Course Imae"/></a></Link>
                                </div>
                                <div className="info">
                                    <span className="price">Free</span>
                                    <span className="date">Dec 23, 2022</span>
                                    <h3 className="title"><Link to={`/courses/course/${item.id}`} ><a href="/course/course">{item.title}</a></Link></h3>
                                    <div className="desc">
                                        <p>Now more than ever, corporations are investing heavily in IT. The quality of these investments affects the daily …</p>
                                    </div>
                                </div>
                            </div>
                         ))}
                            {/* Course End */}

                            {/* Animation Shape Start */}
                            <div className="intro1-scene scene">
                                <span data-depth="4"><img src="assets/images/shape-animation/shape-1.png" alt=""/></span>
                            </div>
                            {/* Animation Shape End */}

                        </div>
                    </div>
                    {/* Intro One Course End */}

                </div>
            </div>

            {/* Section Bottom Shape Start */}
            <div className="section-bottom-shape">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" height="100">
                    <path d="M 0 0 L100 0 Q 50 200 0 0"></path>
                </svg>
            </div>
            {/* Section Bottom Shape End */}

        </div>
        {/* Slider/Intro Section End */}

        {/* Feature Section Start */}
        <div className="section">
            <div className="container">

                {/* Section Title Start */}
                <div className="section-title text-center" data-aos="fade-up">
                    <span className="sub-title">Education for everyone</span>
                    <h2 className="title">Online <span>Coaching Lessons</span> For Remote Learning.</h2>
                </div>
                {/* Section Title End */}

                {/* Feature Wrapper Start */}
                <div className="row row-cols-xl-4 row-cols-sm-2 row-cols-1 g-0">

                    {/* Feature Start (Icon Box) */}
                    <div className="col" data-aos="fade-up">
                        <a href="/courses-grid-1.html" className="icon-box text-center" data-vivus-hover>
                            <div className="icon"><img className="svgInject" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/svg/linea/linea-basic-flag2.svg" alt=""/></div>
                            <div className="content">
                                <h3 className="title">Life Mentoring</h3>
                                <div className="desc">
                                    <p>Get some soul healing and guidance for your future career and vocational directions.</p>
                                </div>
                                <span className="link">Start here <i className="fas fa-arrow-right"></i></span>
                            </div>
                        </a>
                    </div>
                    {/* Feature Start (Icon Box) */}

                    {/* Feature Start (Icon Box) */}
                    <div className="col" data-aos="fade-up">
                        <a href="/courses-grid-1.html" className="icon-box text-center" data-vivus-hover>
                            <div className="icon"><img className="svgInject" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/svg/linea/linea-basic-gear.svg" alt=""/></div>
                            <div className="content">
                                <h3 className="title">Self Development</h3>
                                <div className="desc">
                                    <p>Develop skills for career of various majors including computer science & language competence.</p>
                                </div>
                                <span className="link">Discover now <i className="fas fa-arrow-right"></i></span>
                            </div>
                        </a>
                    </div>
                    {/* Feature Start (Icon Box) */}

                    {/* Feature Start (Icon Box) */}
                    <div className="col" data-aos="fade-up">
                        <a href="/courses-grid-1.html" className="icon-box text-center" data-vivus-hover>
                            <div className="icon"><img className="svgInject" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/svg/linea/linea-basic-life-buoy.svg" alt=""/></div>
                            <div className="content">
                                <h3 className="title">Ask Consultancy</h3>
                                <div className="desc">
                                    <p>Make a reservation with our special consultant to learn what's best for your dream career.</p>
                                </div>
                                <span className="link">Get Free Quote <i className="fas fa-arrow-right"></i></span>
                            </div>
                        </a>
                    </div>
                    {/* Feature Start (Icon Box) */}

                    {/* Feature Start (Icon Box) */}
                    <div className="col" data-aos="fade-up">
                        <a href="/courses-grid-1.html" className="icon-box text-center" data-vivus-hover>
                            <div className="icon"><img className="svgInject" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/svg/linea/linea-basic-display.svg" alt=""/></div>
                            <div className="content">
                                <h3 className="title">Remote Learning</h3>
                                <div className="desc">
                                    <p>Learn from anywhere in the world on desktop, tablet or mobile phone with an Internet connection.</p>
                                </div>
                                <span className="link">Start now <i className="fas fa-arrow-right"></i></span>
                            </div>
                        </a>
                    </div>
                    {/* Feature Start (Icon Box) */}

                </div>
                {/* Feature Wrapper End */}

            </div>
        </div>
        {/* Feature Section End */}

        {/* Teacher Quote Section Start */}
        <div className="section section-padding">
            <div className="container">

                {/* Teacher Quote Wrapper Start */}
                <div className="teacher-quote-wrapper" data-aos="fade-up">
                    {/* Teacher Quote Start */}
                    <div className="teacher-quote">
                        <div className="image"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/others/teracher-quote-1.jpg" alt=""/></div>
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Ger rid of <span>Old School Assumptions</span></h2>
                            </div>
                            <div className="row">
                                <div className="quote">
                                    <p>I assist learners in finding new effective belief and get rid of the outdated, discouraged and irrelevant life attitudes. You're upgrading to be a better you.</p>
                                </div>
                                <div className="experience">
                                    <span className="amount">19<span>years</span></span>
                                    <h6 className="title">Work Experience</h6>
                                    <a href="/courses-grid-1.html" className="link">Get Free Guide <i className="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Teacher Quote End */}
                </div>
                {/* Teacher Quote Wrapper End */}

            </div>
        </div>
        {/* Teacher Quote Section End */}

        {/* Testimonial Section Start */}
        <div className="section section-padding bg-pattern fix" data-bg-image="assets/images/bg/background-pattern-grid-line.png" data-bg-color="#f5f1ed">
            <div className="container">

                {/* Section Title Start */}
                <div className="section-title text-center" data-aos="fade-up">
                    <span className="sub-title">MAXIMIZE YOUR POTENTIALS and possibilities</span>
                    <h2 className="title">Learn the secrets to <span>Life Success</span>, <br/>these people have got the key.​</h2>
                </div>
                {/* Section Title End */}

                {/* Funfact Wrapper Start */}
                <div className="row max-mb-70">
                    <div className="col-lg-9 mx-auto">
                        <div className="row row-cols-md-3 row-cols-1 max-mb-n30">

                            {/* Funfact Start */}
                            <div className="col max-mb-30" data-aos="fade-up">
                                <div className="funfact">
                                    <div className="number"><span className="counter">3.092</span>+</div>
                                    <h6 className="text">Registered Enrolls</h6>
                                </div>
                            </div>
                            {/* Funfact End */}

                            {/* Funfact Start */}
                            <div className="col max-mb-30" data-aos="fade-up">
                                <div className="funfact">
                                    <div className="number"><span className="counter">1.926</span></div>
                                    <h6 className="text">Finished sessions</h6>
                                </div>
                            </div>
                            {/* Funfact End */}

                            {/* Funfact Start */}
                            <div className="col max-mb-30" data-aos="fade-up">
                                <div className="funfact">
                                    <div className="number"><span className="counter">100</span>%</div>
                                    <h6 className="text">Satisfaction rate</h6>
                                </div>
                            </div>
                            {/* Funfact End */}

                        </div>
                    </div>
                </div>
                {/* Funfact Wrapper End */}

                {/*Testimonial Slider Start */}
                <div className="testimonial-slider swiper-container" data-aos="fade-up">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide">
                            <div className="testimonial">
                                <div className="image"><img src="assets/images/testimonial/70/testimonial-1.jpg" alt=""/></div>
                                <div className="content">
                                    <p>I am happy with their arrangement of lessons and subjects. They reflect a scientific investigation into effective methods to adopt for learners.</p>
                                    <h6 className="name">Luvic Dubble</h6>
                                    <span className="position">Private Tutor</span>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="testimonial">
                                <div className="image"><img src="assets/images/testimonial/70/testimonial-2.jpg" alt=""/></div>
                                <div className="content">
                                    <p>I'm a very strict person so I require everything to be organized and neat. Then, I'll be able to make things right and shine. MaxCoach guys just got me.</p>
                                    <h6 className="name">Florence Themes</h6>
                                    <span className="position">Multimedia Admin</span>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="testimonial">
                                <div className="image"><img src="assets/images/testimonial/70/testimonial-3.jpg" alt=""/></div>
                                <div className="content">
                                    <p>I am free to learn at my own pace, follow my own schedule and choose the subject I want to learn from the syllabus. Great study portal for people like me.</p>
                                    <h6 className="name">Mina Hollace</h6>
                                    <span className="position">Freelancer</span>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="testimonial">
                                <div className="image"><img src="assets/images/testimonial/70/testimonial-4.jpg" alt=""/></div>
                                <div className="content">
                                    <p>I need to get a certification for English proficiency and MaxCoach is my best choice. Their tutors are smart and professional when dealing with students.</p>
                                    <h6 className="name">Madley Pondor</h6>
                                    <span className="position">IT Specialist</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Testimonial Slider End */}

            </div>
        </div>
        {/* Testimonial Section End */}

        {/* Video Section Start */}
        <div id="video-section-1" className="section bg-pattern position-relative" data-bg-image="assets/images/bg/background-pattern-grid-line.png" data-bg-color="#f5f1ed">

            <div className="container">

                {/* Section Title Start */}
                <div className="section-title text-center" data-aos="fade-up">
                    <span className="sub-title">The combination of all senses into learning</span>
                    <h2 className="title">Learn with all your <span>SENSES​</span></h2>
                </div>
                {/* Section Title End */}

                {/* About Me Video Wrapper Start */}
                <div className="about-me-video-wrapper">

                    {/* About Me Video Start */}
                    <a href="/https://www.youtube.com/watch?v=Oq65fZr7wi8" className="about-me-video video-popup" data-aos="fade-up">
                        <img className="image" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/about/about-me/about-me-popup-video-poster.jpg" alt=""/>
                        <img className="icon" src="assets/images/icons/icon-youtube-play.png" alt=""/>
                    </a>
                    {/* About Me Video End */}

                    {/* Animation Shape Start */}
                    <div className="shape shape-1 scene">
                        <span data-depth="3">
                            <img src="assets/images/shape-animation/shape-2.svg" alt="" className="svgInject"/>
                        </span>
                    </div>
                    <div className="shape shape-2 scene">
                        <span data-depth="-3"><img src="assets/images/shape-animation/shape-3.png" alt=""/></span>
                    </div>
                    <div className="shape shape-3 scene">
                        <span data-depth="4">shape 3</span>
                    </div>
                    <div className="shape shape-4 scene">
                        <span data-depth="4"><img src="assets/images/shape-animation/shape-1.png" alt=""/></span>
                    </div>
                    {/* Animation Shape End */}

                </div>
                {/* About Me Video Wrapper End */}

            </div>

            {/* Section Bottom Shape Start */}
            <div className="section-bottom-shape">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" height="100">
                    <path className="elementor-shape-fill" d="M 0 0 L0 100 L100 100 L100 0 Q 50 200 0 0"></path>
                </svg>
            </div>
            {/* Section Bottom Shape End */}

        </div>
        {/* Video Section End */}

        {/* Course Section Start */}
        <div className="section section-padding">
            <div className="container">

                {/* Section Title Start */}
                <div className="section-title text-center" data-aos="fade-up">
                    <span className="sub-title">Learn at your Pace</span>
                    <h2 className="title">Current Signature Courses</h2>
                </div>
                {/* Section Title End */}

                {/* Course Wrapper Start */}
                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">

                    {/* Course Start */}
                    {filteredData.map(item => (
                    <div className="col max-mb-30" data-aos="fade-up" key={item.id}>
                        <div className="course-2" onClick={handleProductClick}>
                            <div className="thumbnail">
                            <Link to={`/courses/course/${item.id}`} >  <a href="/courses/course/" className="image"><img src={item.imagec}  alt="Course Imae" /></a></Link>
                            </div>
                            <div className="info">
                                <span className="price">${item.price}</span>
                                <span className="date">Mar 20, 2022</span>
                                <h3 className="title">
                                <Link to={`/courses/course/${item.id}`} > <a href="/courses/course/">{item.title}
                                    </a></Link>
                                    </h3>
                            </div>
                        </div>
                    </div>
                    ))}
                    {/* Course End */}

                   
                </div>
                {/* Course Wrapper End */}

                <div className="row max-mt-70">
                    <div className="text-center col-lg-7 mx-auto">
                        <a className="link link-lg" href="/courses-grid-1.html">Get the most dedicated consultation for your life-changing course. Earn a certification for your effort and passion <mark>Get Free Guide <i className="fas fa-arrow-right"></i></mark></a>
                    </div>
                </div>

            </div>
        </div>
        {/* Course Section End */}

        {/* Blog Section Start */}
        <div className="blog-section section section-padding bg-pattern-one" data-bg-color="#f5f5f5" data-bg-image="assets/images/bg/maxcoach-shape-03.png">
            <div className="container blog-shape-animation">
                {/* Section Title Start */}
                <div className="section-title text-center" data-aos="fade-up">
                    <span className="sub-title">study with your favorite tutors</span>
                    <h2 className="title">Secrets of learning revealed in blogs</h2>
                </div>
                {/* Section Title End */}
                <div className="row isotope-grid">

                    <div className="grid-sizer col-1"></div>

                    <div className="col-xl-3 col-lg-6 col-md-6 grid-item" data-aos="fade-up">
                        <div className="blog-2 mt-150 mt-md-0 mt-sm-0 mt-xs-0 mb-30">
                            <div className="thumbnail">
                                <a href="/blog-details.html" className="image"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/blog/270/blog-01.jpg" alt="Blog Imae"/></a>
                            </div>
                            <div className="info">
                                <a href="/blog-details.html" className="category">Online Education</a>
                                <h3 className="title"><a href="/blog-details.html">Become a Better Blogger: Content Planning</a></h3>
                                <ul className="meta">
                                    <li><i className="fas fa-calendar"></i>Dec 11, 2022</li>
                                    <li><i className="fas fa-eye"></i>47 views</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 plr-40 grid-item" data-aos="fade-up">
                        <div className="blog-2 mb-30">
                            <div className="thumbnail overlay-thumb">
                                <a href="/blog-details.html" className="image"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/blog/500/blog-03.jpg" alt="Blog Imae"/></a>
                            </div>
                            <div className="info content-absolute">
                                <a href="/blog-details.html" className="category">Learning Strategies</a>
                                <h3 className="title"><a href="/blog-details.html">Five Essential Online Learning Strategies</a></h3>
                                <ul className="meta">
                                    <li><i className="fas fa-calendar"></i>Dec 11, 2022</li>
                                    <li><i className="fas fa-eye"></i>32 views</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 grid-item" data-aos="fade-up">
                        <div className="blog-2 mt-50 mt-sm-0 mt-xs-0 mb-30">
                            <div className="thumbnail">
                                <a href="/blog-details.html" className="image"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/blog/270/blog-02.jpg" alt="Blog Imae"/></a>
                            </div>
                            <div className="info">
                                <a href="/blog-details.html" className="category">Learning Strategies</a>
                                <h3 className="title"><a href="/blog-details.html">Five Essential Online Learning Strategies</a></h3>
                                <ul className="meta">
                                    <li><i className="fas fa-calendar"></i>Dec 11, 2022</li>
                                    <li><i className="fas fa-eye"></i>32 views</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row max-mt-70">
                    <div className="text-center col-lg-7 mx-auto">
                        <a className="link link-lg" href="/blog-grid.html">Get into details now?​ <mark>View all posts <i className="fas fa-arrow-right"></i></mark></a>
                    </div>
                </div>


                {/* Animation Shape Start */}
                <div className="shape shape-1 scene">
                    <span data-depth="4">shape 3</span>
                </div>
                <div className="shape shape-2 scene">
                    <span data-depth="-3"><img src="assets/images/shape-animation/shape-3.png" alt=""/></span>
                </div>
                <div className="shape shape-3 scene">
                    <span data-depth="3">
                        <img src="assets/images/shape-animation/shape-2.svg" alt="" className="svgInject"/>
                    </span>
                </div>
                {/* Animation Shape End */}
            </div>
        </div>
        {/* Blog Section End */}

        {/* Brand Section Start */} 
        <div className="brand-section section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="brand-wrapper">
                            <div className="brand-list">
                                <div className="brand-carousel swiper-container" data-aos="fade-up">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide brand">
                                            <a href="/#">
                                                <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/brand/client-logo-01.png" alt="logo imae"/>
                                            </a>
                                        </div>

                                        <div className="swiper-slide brand">
                                            <a href="/#">
                                                <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/brand/client-logo-02.png" alt="logo imae"/>
                                            </a>
                                        </div>

                                        <div className="swiper-slide brand">
                                            <a href="/#">
                                                <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/brand/client-logo-03.png" alt="logo imae"/>
                                            </a>
                                        </div>

                                        <div className="swiper-slide brand">
                                            <a href="/#">
                                                <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/brand/client-logo-04.png" alt="logo imae"/>
                                            </a>
                                        </div>

                                        <div className="swiper-slide brand">
                                            <a href="/#">
                                                <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/brand/client-logo-05.png" alt="logo imae"/>
                                            </a>
                                        </div>

                                        <div className="swiper-slide brand">
                                            <a href="/#">
                                                <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/brand/client-logo-06.png" alt="logo imae"/>
                                            </a>
                                        </div>

                                        <div className="swiper-slide brand">
                                            <a href="/#">
                                                <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/brand/client-logo-07.png" alt="logo imae"/>
                                            </a>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Brand Section End */}

        

        {/* Newsletter Popup Start */}
        {/* <div id="max-popup" className="max-popup-section section">
            <div className="max-popup-dialog animated fadeInUp">
                <button className="max-popup-close"><i className="fal fa-times"></i></button>
                <div className="max-popup-dialog-inner">
                    <div className="row">
                        <div className="col-md-5 col-12 d-none d-md-block">
                            <div className="freecourse-popup-image">
                                <img src="assets/images/others/popup-free-course.png" alt=""/>
                            </div>
                        </div>
                        <div className="col-md-7 col-12 align-self-center">
                            <div className="freecourse-popup-content">
                                <h6 className="sub-title">Get Our Course free</h6>
                                <h2 className="title">Awesome for Website</h2>
                                <div className="freecourse-download-form">
                                    <form action="#">
                                        <div className="row max-mb-n20">
                                            <div className="col-12 max-mb-20">
                                                <input type="email" placeholder="Your E-mail">
                                            </div>
                                            <div className="col-12 max-mb-20">
                                                <button className="btn btn-primary btn-hover-secondary w-100">Download now</button>
                                            </div>
                                            <div className="col-12 max-mb-20">
                                                <small className="form-text text-center"><i className="fal fa-lock-alt mr-2"></i> Your infomation will never be shared with any third party</small>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        {/* Newsletter Popup End */}

        {/* Scroll Top Start */}
        <a href="/#" className="scroll-top" id="scroll-top">
            <i className="arrow-top fal fa-long-arrow-up"></i>
            <i className="arrow-bottom fal fa-long-arrow-up"></i>
        </a>
        {/* Scroll Top End */}


        
        
    </>
  )
}

export default Hompage