import React from 'react'
import { useParams } from 'react-router-dom'
import jsonfile from "../components/Shopsec/shopD.json"
import { useState } from 'react'

function ProductDetail() {
    const {productId} = useParams()
  
 
    const foundUser = jsonfile.find(item => item.id == productId)
      const [images, setImages] = useState({
        image1: foundUser.subimages1,
        image2: foundUser.subimages2,
        image3: foundUser.subimages3,
        image4: foundUser.subimages4
    })

    const [activeimage, setActiveImage] = useState(foundUser.image)
    
    
    if(foundUser.type ==="sproduct"){

    
    
  return (
    
       <>
       <div className="page-title-section section">
            {/* <div className="page-title">
                <div className="container">
                    <h4 className="title">Product Details</h4>
                </div>
            </div> */}
            <div className="page-breadcrumb">
                <div className="container">
                    <ul className="breadcrumb">
                        <li><a href="/">Home</a></li>
                        <li className="current">Product Details</li>
                    </ul>
                </div>
            </div>
        </div>



        <div className="section section-padding-bottom-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="single-product-image">
                          
                           <div class="option flex">
                            <img  src={images.image1} onClick={() =>setActiveImage(images.image1)} alt='product imgs'/>
                            <img  src={images.image2} onClick={() =>setActiveImage(images.image2)} alt='product imgs' />
                            <img  src={images.image3} onClick={() =>setActiveImage(images.image3)} alt='product imgs'/>
                            <img  src={images.image4} onClick={() =>setActiveImage(images.image4)} alt='product imgs'/> 
                            
                        </div>
                        
                        <img classNAme="slide" src={activeimage} alt="book"/>
                            
                            <div className="product-badges">
                                <span className="onsale">-29%</span>
                            </div>
                            
                        </div>
                        
                    </div>
                    <div className="col-lg-6">
                        <div className="single-product-content">
                            <h3 className="title">{foundUser.Title}  </h3>
                            <span className="prices">
                                <span className="price-new">${foundUser.price}</span>
                            <span className="price-old">${`${foundUser.price *2}`}</span>
                            </span>
                            <div className="product-ratings">
                                <span className="star-rating">
                                    <span className="rating-active">ratings</span>
                                </span>
                                <a href="/" className="review-link">(<span className="count">2</span> customer reviews)</a>
                            </div>
                            <div className="product-description">
                                <p>Learn clear and concise Search Engine Optimisation (SEO) strategies to boost your website’s search ranking on Google, Bing, and Yahoo in 2020, regardless of your prior experience. Have you ever considered why your online business isn’t sitting at the number one spot on Google, or even on the first page of results? Now is the time to discover why SEO is one of your greatest allies in generating additional traffic and conversions in 2020.</p>
                            </div>
                            <div className="quantity-button-wrapper">
                                <div className="quantity">
                                    <label>Quantity</label>
                                    <input type="number" value="1" min="1"/>
                                </div>
                                <p className="stock in-stock">99 in stock</p>
                            </div>
                            <div className="product-action">
                                <button className="btn btn-primary btn-hover-secondary">Add to cart</button>
                                <button className="btn btn-wishlist btn-box"><i className="far fa-heart"></i></button>
                                <button className="btn btn-compare btn-box"><i className="fas fa-random"></i></button>
                            </div> 

                            <div className="product-meta">
                                <div className="meta-item">
                                    <h6>Sku:</h6>
                                    <div className="meta-content">
                                        <span className="sku">MCB-001</span>
                                    </div>
                                </div>

                                <div className="meta-item">
                                    <h6>Category:</h6>
                                    <div className="meta-content">
                                        <a href="/#" rel="tag">Book</a>
                                    </div>
                                </div>
                                <div className="meta-item">
                                    <h6>Tags:</h6>
                                    <div className="meta-content">
                                        <a href="/#" rel="tag">business</a> / <a href="/#" rel="tag">seo</a>
                                    </div>
                                </div>
                            </div>

                            <div className="entry-product-share">
                                <h6>Share:</h6>
                                <div className="author-social-networks">
                                    <div className="inner">

                                        <a className="hint--bounce hint--top hint--primary" aria-label="Facebook" href="/#" target="_blank">
                                            <i className="fab fa-facebook-f"></i>
                                        </a>

                                        <a className="hint--bounce hint--top hint--primary" aria-label="Twitter" href="/#" target="_blank">
                                            <i className="fab fa-twitter"></i>
                                        </a>

                                        <a className="hint--bounce hint--top hint--primary" aria-label="Linkedin" href="/#" target="_blank">
                                            <i className="fab fa-linkedin"></i>
                                        </a>

                                        <a className="hint--bounce hint--top hint--primary" aria-label="Tumblr" href="/#" target="_blank">
                                            <i className="fab fa-tumblr-square"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        


        <div className="single-product-information section section-padding-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ul className="nav product-info-tab-list justify-content-center">
                            <li><a className="active" data-bs-toggle="tab" href="/#tab-description">Description</a></li>
                            <li><a data-bs-toggle="tab" href="/#tab-reviews">Reviews (2)</a></li>
                        </ul>
                        <div className="tab-content product-infor-tab-content">
                            <div className="tab-pane fade show active" id="tab-description">
                                <div className="row">
                                    <div className="offset-lg-2 col-lg-8">
                                        <div className="description-list">
                                            <ul>
                                                <li>How to get your online business to appear on the first page of search results;</li>
                                                <li>The four pillars of SEO that you must respect to make your online business success;</li>
                                                <li>How to carry out a full SEO audit on your website;</li>
                                                <li>How to select the right keywords for your business that will generate traffic to your site;</li>
                                                <li>Top tips for the best SEO practices in 2020;</li>
                                                <li>How to avoid getting blacklisted and penalized.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab-reviews">
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2">
                                        <div className="comment-list-wrapper review-list-wrapper">

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <ol className="comment-list">
                                                        <li className="comment border-bottom">
                                                            <div className="comment-2">
                                                                <div className="comment-author vcard">
                                                                    <img alt="" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/comment/comment1.jpeg"/>
                                                                </div>
                                                                <div className="comment-content">
                                                                    <div className="meta">
                                                                        <h6 className="fn">Edna Watson</h6>
                                                                        <span className="star-rating">
                                                                            <span className="rating-active">ratings</span>
                                                                        </span>
                                                                    </div>
                                                                    <div className="comment-text">
                                                                        <p>Thanks for always keeping your HTML Template up to date. Your level of support and dedication is second to none.</p>
                                                                    </div>

                                                                    <div className="comment-actions">
                                                                        <div className="comment-datetime"> November 16, 2018 at 4:31 am </div><span> | </span>
                                                                        <a className="comment-reply-link" href="/#">Reply</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="comment border-bottom pb-25">
                                                            <div className="comment-2">
                                                                <div className="comment-author vcard">
                                                                    <img alt="" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/comment/comment2.jpeg"/>
                                                                </div>
                                                                <div className="comment-content">
                                                                    <div className="meta">
                                                                        <h6 className="fn">Owen Christ</h6>
                                                                        <span className="star-rating">
                                                                            <span className="rating-active">ratings</span>
                                                                        </span>
                                                                    </div>
                                                                    <div className="comment-text">
                                                                        <p>Thanks for always keeping your HTML Template up to date. Your level of support and dedication is second to none.</p>
                                                                    </div>

                                                                    <div className="comment-actions">
                                                                        <div className="comment-datetime"> November 19, 2018 at 4:31 am </div><span> | </span>
                                                                        <a className="comment-reply-link" href="/#">Reply</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="comment-form-wrap review-form-wrap">
                                            <div className="comment-respond">
                                                <h3 className="title">Add a review</h3>
                                                <p className="comment-notes max-mb-20"><span id="email-notes">Your email address will not be published.</span> Required fields are marked <span className="required">*</span></p>


                                                <div className="comment-form-rating max-mb-30">
                                                    <label>Your rating:</label>
                                                    <span className="rating"><span className="star"></span></span>
                                                </div>

                                                <form action="#" method="post">
                                                    <div className="row">
                                                        <div className="col-md-6 col-12 max-mb-30">
                                                            <input type="text" placeholder="Your Name *" name="name"/>
                                                        </div>
                                                        <div className="col-md-6 col-12 max-mb-30">
                                                            <input type="email" placeholder="Email *" name="email"/>
                                                        </div>
                                                        <div className="col-12 max-mb-30">
                                                            <textarea name="message" placeholder="Your Comment"></textarea>
                                                        </div>
                                                        <div className="col-12">
                                                            <p className="comment-form-cookies-consent justify-content-start text-left">
                                                                <input id="comment-cookies-consent" name="comment-cookies-consent" type="checkbox" value="yes"/>
                                                                <label for="comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                                                            </p>
                                                        </div>
                                                        <div className="col-12 text-center">
                                                            <button className="btn btn-primary btn-hover-secondary btn-width-180 btn-height-60">Submit</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

     </>   
    
  )
} else {
    return (
    <>
    <div className="page-title-section section">
         {/* <div className="page-title">
             <div className="container">
                 <h4 className="title">Product Details</h4>
             </div>
         </div> */}
         <div className="page-breadcrumb">
             <div className="container">
                 <ul className="breadcrumb">
                     <li><a href="/">Home</a></li>
                     <li className="current">Product Details</li>
                 </ul>
             </div>
         </div>
     </div>



     <div className="section section-padding-bottom-80">
         <div className="container">
             <div className="row">
                 <div className="col-lg-6">
                     <div className="single-product-image">
                       
                       
                     
                     <img  src={foundUser.image} alt="book"/>
                         
                         <div className="product-badges">
                             <span className="onsale">-29%</span>
                         </div>
                         
                     </div>
                     
                 </div>
                 <div className="col-lg-6">
                     <div className="single-product-content">
                         <h3 className="title">{foundUser.Title}  </h3>
                         <span className="prices">
                             <span className="price-new">${foundUser.price}</span>
                         <span className="price-old">${`${foundUser.price *2}`}</span>
                         </span>
                         <div className="product-ratings">
                             <span className="star-rating">
                                 <span className="rating-active">ratings</span>
                             </span>
                             <a href="/#reviews" className="review-link">(<span className="count">2</span> customer reviews)</a>
                         </div>
                         <div className="product-description">
                             <p>Learn clear and concise Search Engine Optimisation (SEO) strategies to boost your website’s search ranking on Google, Bing, and Yahoo in 2020, regardless of your prior experience. Have you ever considered why your online business isn’t sitting at the number one spot on Google, or even on the first page of results? Now is the time to discover why SEO is one of your greatest allies in generating additional traffic and conversions in 2020.</p>
                         </div>
                         <div className="quantity-button-wrapper">
                             <div className="quantity">
                                 <label>Quantity</label>
                                 <input type="number" value="1" min="1"/>
                             </div>
                             <p className="stock in-stock">99 in stock</p>
                         </div>
                         <div className="product-action">
                             <button className="btn btn-primary btn-hover-secondary">Add to cart</button>
                             <button className="btn btn-wishlist btn-box"><i className="far fa-heart"></i></button>
                             <button className="btn btn-compare btn-box"><i className="fas fa-random"></i></button>
                         </div> 

                         <div className="product-meta">
                             <div className="meta-item">
                                 <h6>Sku:</h6>
                                 <div className="meta-content">
                                     <span className="sku">MCB-001</span>
                                 </div>
                             </div>

                             <div className="meta-item">
                                 <h6>Category:</h6>
                                 <div className="meta-content">
                                     <a href="/#" rel="tag">Book</a>
                                 </div>
                             </div>
                             <div className="meta-item">
                                 <h6>Tags:</h6>
                                 <div className="meta-content">
                                     <a href="/#" rel="tag">business</a> / <a href="/#" rel="tag">seo</a>
                                 </div>
                             </div>
                         </div>

                         <div className="entry-product-share">
                             <h6>Share:</h6>
                             <div className="author-social-networks">
                                 <div className="inner">

                                     <a className="hint--bounce hint--top hint--primary" aria-label="Facebook" href="/#" target="_blank">
                                         <i className="fab fa-facebook-f"></i>
                                     </a>

                                     <a className="hint--bounce hint--top hint--primary" aria-label="Twitter" href="/#" target="_blank">
                                         <i className="fab fa-twitter"></i>
                                     </a>

                                     <a className="hint--bounce hint--top hint--primary" aria-label="Linkedin" href="/#" target="_blank">
                                         <i className="fab fa-linkedin"></i>
                                     </a>

                                     <a className="hint--bounce hint--top hint--primary" aria-label="Tumblr" href="/#" target="_blank">
                                         <i className="fab fa-tumblr-square"></i>
                                     </a>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     </div>
     


     <div className="single-product-information section section-padding-bottom">
         <div className="container">
             <div className="row">
                 <div className="col-12">
                     <ul className="nav product-info-tab-list justify-content-center">
                         <li><a className="active" data-bs-toggle="tab" href="/#tab-description">Description</a></li>
                         <li><a data-bs-toggle="tab" href="/#tab-reviews">Reviews (2)</a></li>
                     </ul>
                     <div className="tab-content product-infor-tab-content">
                         <div className="tab-pane fade show active" id="tab-description">
                             <div className="row">
                                 <div className="offset-lg-2 col-lg-8">
                                     <div className="description-list">
                                         <ul>
                                             <li>How to get your online business to appear on the first page of search results;</li>
                                             <li>The four pillars of SEO that you must respect to make your online business success;</li>
                                             <li>How to carry out a full SEO audit on your website;</li>
                                             <li>How to select the right keywords for your business that will generate traffic to your site;</li>
                                             <li>Top tips for the best SEO practices in 2020;</li>
                                             <li>How to avoid getting blacklisted and penalized.</li>
                                         </ul>
                                     </div>
                                 </div>
                             </div>
                         </div>
                         <div className="tab-pane fade" id="tab-reviews">
                             <div className="row">
                                 <div className="col-lg-8 offset-lg-2">
                                     <div className="comment-list-wrapper review-list-wrapper">

                                         <div className="row">
                                             <div className="col-lg-12">
                                                 <ol className="comment-list">
                                                     <li className="comment border-bottom">
                                                         <div className="comment-2">
                                                             <div className="comment-author vcard">
                                                                 <img alt="" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/comment/comment1.jpeg"/>
                                                             </div>
                                                             <div className="comment-content">
                                                                 <div className="meta">
                                                                     <h6 className="fn">Edna Watson</h6>
                                                                     <span className="star-rating">
                                                                         <span className="rating-active">ratings</span>
                                                                     </span>
                                                                 </div>
                                                                 <div className="comment-text">
                                                                     <p>Thanks for always keeping your HTML Template up to date. Your level of support and dedication is second to none.</p>
                                                                 </div>

                                                                 <div className="comment-actions">
                                                                     <div className="comment-datetime"> November 16, 2018 at 4:31 am </div><span> | </span>
                                                                     <a className="comment-reply-link" href="/#">Reply</a>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                     </li>
                                                     <li className="comment border-bottom pb-25">
                                                         <div className="comment-2">
                                                             <div className="comment-author vcard">
                                                                 <img alt="" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/comment/comment2.jpeg"/>
                                                             </div>
                                                             <div className="comment-content">
                                                                 <div className="meta">
                                                                     <h6 className="fn">Owen Christ</h6>
                                                                     <span className="star-rating">
                                                                         <span className="rating-active">ratings</span>
                                                                     </span>
                                                                 </div>
                                                                 <div className="comment-text">
                                                                     <p>Thanks for always keeping your HTML Template up to date. Your level of support and dedication is second to none.</p>
                                                                 </div>

                                                                 <div className="comment-actions">
                                                                     <div className="comment-datetime"> November 19, 2018 at 4:31 am </div><span> | </span>
                                                                     <a className="comment-reply-link" href="/#">Reply</a>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                     </li>
                                                 </ol>
                                             </div>
                                         </div>
                                     </div>

                                     <div className="comment-form-wrap review-form-wrap">
                                         <div className="comment-respond">
                                             <h3 className="title">Add a review</h3>
                                             <p className="comment-notes max-mb-20"><span id="email-notes">Your email address will not be published.</span> Required fields are marked <span className="required">*</span></p>


                                             <div className="comment-form-rating max-mb-30">
                                                 <label>Your rating:</label>
                                                 <span className="rating"><span className="star"></span></span>
                                             </div>

                                             <form action="#" method="post">
                                                 <div className="row">
                                                     <div className="col-md-6 col-12 max-mb-30">
                                                         <input type="text" placeholder="Your Name *" name="name"/>
                                                     </div>
                                                     <div className="col-md-6 col-12 max-mb-30">
                                                         <input type="email" placeholder="Email *" name="email"/>
                                                     </div>
                                                     <div className="col-12 max-mb-30">
                                                         <textarea name="message" placeholder="Your Comment"></textarea>
                                                     </div>
                                                     <div className="col-12">
                                                         <p className="comment-form-cookies-consent justify-content-start text-left">
                                                             <input id="comment-cookies-consent" name="comment-cookies-consent" type="checkbox" value="yes"/>
                                                             <label for="comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                                                         </p>
                                                     </div>
                                                     <div className="col-12 text-center">
                                                         <button className="btn btn-primary btn-hover-secondary btn-width-180 btn-height-60">Submit</button>
                                                     </div>
                                                 </div>
                                             </form>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     </div>

  </>  )

}
}

export default ProductDetail