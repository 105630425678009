import React from 'react'

function Success() {
  return (
    <>
    
    <div className="page-title-section section pt-0" data-bg-color="#f5f5f5">
            <div className="page-breadcrumb position-static">
                <div className="container">
                    <ul className="breadcrumb">
                        <li><a href="index.html">Home</a></li>
                        <li className="current">Success Story</li>
                    </ul>
                </div>
            </div>
        </div>
        {/* Page Title Section End */}

        {/* Video Section Start */}
        <div id="video-section-1" className="section position-relative" data-bg-color="#f5f5f5">

            <div className="container">

                {/* Section Title Start */}
                <div className="section-title text-center" data-aos="fade-up">
                    <span className="sub-title">Success story</span>
                    <h2 className="title">The biggest turning point in my life: <br className="d-none d-md-block"/> Wonderful choice.</h2>
                </div>
                {/* Section Title End */}

                {/* About Me Video Wrapper Start */}
                <div className="about-me-video-wrapper">

                    {/* About Me Video Start */}
                    <a href="https://www.youtube.com/watch?v=eS9Qm4AOOBY" className="about-me-video success-story-video video-popup" data-aos="fade-up">
                        <img className="image" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/Video/success-story-poster-image.jpg" alt=""/>
                        <img className="icon" src="assets/images/icons/icon-youtube-play.png" alt=""/>
                    </a>
                    {/* About Me Video End */}

                </div>
                {/* About Me Video Wrapper End */}

            </div>

            {/* Section Bottom Shape Start */}
            <div className="section-bottom-shape d-md-block d-none">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" height="100">
                    <path className="elementor-shape-fill" d="M 0 0 L0 100 L100 100 L100 0 Q 50 200 0 0"></path>
                </svg>
            </div>
            {/* Section Bottom Shape End */}

        </div>
        {/* Video Section End */}

        {/* Success Quote Section Start  */}
        <div className="success-quote-section section section-padding-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="success-quote-heading">
                            <h3 className="title">
                                I learned one thing about online education is that
                                <span>highly motivated individuals</span>
                                always find a way to tackle the challenge in the most creative and flexible way.
                            </h3>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="success-quote-content">
                            <p>At Obeddev, we put ourselves in learners’ shoes to understand what they want and need, to build handy options for their education and guide them to a better version of themselves. We do the hard work to pave the ways for innovative higher education with advanced delivery modalities.</p>
                            <p>From high school students, tertiary pupils, graduates or post-graduate learners, learners of any levels can easily find a suitable online program for themselves. It’s now convenient than the past to take an online course for improving the degree, becoming working professionals or pursuing a better job with valid certification… MaxCoach offers a wide range of online options for students around the globe. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Success Quote Section End  */}

        {/* Success Faq Section Start */}
        <div className="success-faq-section section section-padding-top">
            <div className="container">
                <div className="row max-mb-n30">
                    <div className="col-lg-8 col-md-8 max-mb-30">
                        <div className="success-faq-image">
                            <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/faq/success-story-image-01.jpg" alt="faq-imae"/>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 max-mb-30">
                        <div className="success-faq-image">
                            <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/faq/success-story-image-02.jpg" alt="faq-imae"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="success-faq-area">

                            <div className="single-success-faq">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="success-faq-heading">
                                            <div className="icon">
                                                <i className="fas fa-arrow-right"></i>
                                            </div>
                                            <div className="text-wrap">
                                                <h4>What are the reasons for learners to choose distant learning (e-learning) over the traditional method?</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="success-faq-content">
                                            <div className="icon">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="text-wrap">
                                                <p>FLEXIBILITY – Work, family, degree—it’s all possible.</p>
                                                <p>Convenience – Study on your time schedule, no commute, stackable programs lead to certificates and degrees.</p>
                                                <p>Proven Modality – More than 5 million students take one or more distance education courses each year.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="single-success-faq">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="success-faq-heading">
                                            <div className="icon">
                                                <i className="fas fa-arrow-right"></i>
                                            </div>
                                            <div className="text-wrap">
                                                <h4>What makes Obeddev different from others? Is that the equipment or the tutor?</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="success-faq-content">
                                            <div className="icon">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="text-wrap">
                                                <p>New Relationships and Networking Opportunities – Learn alongside students who offer diverse levels of experience and perspective. Students enroll from all corners of the world contributing to a rich classroom environment.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="single-success-faq">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="success-faq-heading">
                                            <div className="icon">
                                                <i className="fas fa-arrow-right"></i>
                                            </div>
                                            <div className="text-wrap">
                                                <h4>Which kind of opportunities is opened up to me when I take the lead?</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="success-faq-content">
                                            <div className="icon">
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className="text-wrap">
                                                <p>Career Advancement and Entrepreneurial Opportunities – Advance your career with additional credentials or degrees.

                                                    Quality of Instruction – University of Illinois faculty are among the most accomplished scholars in the world.

                                                    Academic Excellence – Online degree seekers earn the same degree as U of I campus-based degree seeking students.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Success Faq Section End */}

        {/* Course Section Start */}
        <div className="course-section section section-padding">
            <div className="container">
                {/* Section Title Start */}
                <div className="section-title text-center" data-aos="fade-up">
                    <h2 className="title">Recent Courses</h2>
                </div>
                {/* Section Title End */}

                {/* Courses Wrapper Start */}
                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">

                    {/* Course Start */}
                    <div className="col max-mb-30" data-aos="fade-up">
                        <div className="course">
                            <div className="thumbnail">
                                <a href="course-details-standard-sidebar.html" className="image"><img src="assets/images/courses/370/course-1.jpg" alt="Course Imge"/></a>
                            </div>
                            <div className="info">
                                <span className="price">$40<span>.00</span></span>
                                <h3 className="title"><a href="course-details-standard-sidebar.html">Learning to Write as a Professional Author</a></h3>
                                <ul className="meta">
                                    <li><i className="fas fa-file-alt"></i>20 Lessons</li>
                                    <li><i className="fas fa-user-alt"></i>51 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Course End */}

                    {/* Course Start */}
                    <div className="col max-mb-30" data-aos="fade-up">
                        <div className="course">
                            <div className="thumbnail">
                                <span className="badge">Free</span>
                                <a href="course-details-standard-sidebar.html" className="image"><img src="assets/images/courses/370/course-2.jpg" alt="Course Imae"/></a>
                            </div>
                            <div className="info">
                                <span className="price">$0<span>.00</span></span>
                                <h3 className="title"><a href="course-details-standard-sidebar.html">Customer-centric Info-Tech Strategies</a></h3>
                                <ul className="meta">
                                    <li><i className="fas fa-file-alt"></i>23 Lessons</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Course End */}

                    {/* Course Start */}
                    <div className="col max-mb-30" data-aos="fade-up">
                        <div className="course">
                            <div className="thumbnail">
                                <a href="course-details-standard-sidebar.html" className="image"><img src="assets/images/courses/370/course-3.jpg" alt="Course Imag"/></a>
                            </div>
                            <div className="info">
                                <span className="price">$19<span>.00</span></span>
                                <h3 className="title"><a href="course-details-standard-sidebar.html">Open Programming Courses for Everyone: Python</a></h3>
                                <ul className="meta">
                                    <li><i className="fas fa-file-alt"></i>16 Lessons</li>
                                    <li><i className="fas fa-user-alt"></i>57 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Course End */}

                </div>
                {/* Courses Wrapper End */}
            </div>
        </div>
        {/* Course Section End */}

        {/* Gallery Section Start */}
        <div className="gallery-section section">
            <div className="container-fluid p-0">
                <div className="justified-gallery">

                    <div className="grid-item">
                        {/* Single Gallery Start */}
                        <a href="success-story.html" className="single-gallery">
                            <div className="thumbnail">
                                <img className="img-fluid" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/gallery/start-here-gallery-image-01.jpg" alt="gallery-01"/>
                            </div>
                            <div className="content">
                                <h5 className="title">Lonnie Marks</h5>
                                <div className="description">
                                    <p>Tutor</p>
                                </div>
                            </div>
                        </a>
                        {/* Single Gallery End */}
                    </div>

                    <div className="grid-item">
                        {/* Single Gallery Start */}
                        <a href="success-story.html" className="single-gallery">
                            <div className="thumbnail">
                                <img className="img-fluid" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/gallery/start-here-gallery-image-02.jpg" alt="gallery-02"/>
                            </div>
                            <div className="content">
                                <h5 className="title">Lonnie Marks</h5>
                                <div className="description">
                                    <p>Tutor</p>
                                </div>
                            </div>
                        </a>
                        {/* Single Gallery End */}
                    </div>

                    <div className="grid-item">
                        {/* Single Gallery Start */}
                        <a href="success-story.html" className="single-gallery">
                            <div className="thumbnail">
                                <img className="img-fluid" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/gallery/start-here-gallery-image-03.jpg" alt="gallery-03"/>
                            </div>
                            <div className="content">
                                <h5 className="title">Lonnie Marks</h5>
                                <div className="description">
                                    <p>Tutor</p>
                                </div>
                            </div>
                        </a>
                        {/* Single Gallery End */}
                    </div>

                    <div className="grid-item">
                        {/* Single Gallery Start */}
                        <a href="success-story.html" className="single-gallery">
                            <div className="thumbnail">
                                <img className="img-fluid" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/gallery/start-here-gallery-image-04.jpg" alt="gallery-04"/>
                            </div>
                            <div className="content">
                                <h5 className="title">Lonnie Marks</h5>
                                <div className="description">
                                    <p>Tutor</p>
                                </div>
                            </div>
                        </a>
                        {/* Single Gallery End */}
                    </div>

                    <div className="grid-item">
                        {/* Single Gallery Start */}
                        <a href="success-story.html" className="single-gallery">
                            <div className="thumbnail">
                                <img className="img-fluid" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/gallery/start-here-gallery-image-05.jpg" alt="gallery-05"/>
                            </div>
                            <div className="content">
                                <h5 className="title">Lonnie Marks</h5>
                                <div className="description">
                                    <p>Tutor</p>
                                </div>
                            </div>
                        </a>
                        {/* Single Gallery End */}
                    </div>

                    <div className="grid-item">
                        {/* Single Gallery Start */}
                        <a href="success-story.html" className="single-gallery">
                            <div className="thumbnail">
                                <img className="img-fluid" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/gallery/start-here-gallery-image-06.jpg" alt="gallery-06"/>
                            </div>
                            <div className="content">
                                <h5 className="title">Lonnie Marks</h5>
                                <div className="description">
                                    <p>Tutor</p>
                                </div>
                            </div>
                        </a>
                        {/* Single Gallery End */}
                    </div>

                    <div className="grid-item">
                        {/* Single Gallery Start */}
                        <a href="success-story.html" className="single-gallery">
                            <div className="thumbnail">
                                <img className="img-fluid" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/gallery/start-here-gallery-image-07.jpg" alt="gallery-07"/>
                            </div>
                            <div className="content">
                                <h5 className="title">Lonnie Marks</h5>
                                <div className="description">
                                    <p>Tutor</p>
                                </div>
                            </div>
                        </a>
                        {/* Single Gallery End */}
                    </div>

                </div>
            </div>
        </div>
        {/* Gallery Section End */}
    </>
  )
}

export default Success