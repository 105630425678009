import React from 'react'
import { useState } from 'react';
import data from "./components/Posts.json"


function Header() {
   const [filteredData, setFilteredData] = useState([]);
  
  const [wordEntered, setWordEntered] = useState("");


  const [isHovered, setIsHovered] = useState(false);


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = data.filter((value) => {
      return value.title.toLowerCase().includes(searchWord.toLowerCase());
    });
    
    

    if (searchWord === "") {
      setFilteredData([]);
     
    } else {
      setFilteredData(newFilter);
      
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  return (
    
    <div className="header-section header-fluid sticky-header section">
    <div className="header-inner">
        <div className="container position-relative">
            <div className="row justify-content-between align-items-center">

                {/* Header Logo Start */}
                <div className="col-xl-3 col-auto">
                    <div className="header-logo">
                        <a href="/" >
                            <img className="dark-logo" src="assets/images/logo/dark-logo.png" alt="Learts Lgo"/>
                            <img className="light-logo" src="assets/images/logo/light-logo.png" alt="Learts Lgo"/>
                        </a>
                    </div>
                </div>
                {/* Header Logo End */}

                {/* Header Main Menu Start */}
                <div className="col d-none d-xl-block position-static">
                    <nav className="site-main-menu">
                        <ul>
                            <li className="has-children position-static">
                                <a href="/"><span className="menu-text">Home</span></a>
                                <span className="menu-toggle"><i className="fas fa-angle-down"></i></span>

                                <ul className="mega-menu">
                                    <li>
                                        <ul>
                                            <li><a href="/"><span className="menu-text">MaxCoach Education <span className="badge">Hot</span></span></a></li>
                                            <li><a href="/"><span className="menu-text">Course Portal</span></a></li>
                                            <li><a href="/"><span className="menu-text">Distant Learning <span className="badge">Hot</span></span></a></li>
                                            <li><a href="/"><span className="menu-text">Multimedia Pedagogy</span></a></li>
                                            <li><a href="/"><span className="menu-text">Modern Schooling</span></a></li>
                                            <li><a href="/"><span className="menu-text">Remote Training</span></a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li><a href="/"><span className="menu-text">Health Coaching</span></a></li>
                                            <li><a href="/"><span className="menu-text">Gym Coaching</span></a></li>
                                            <li><a href="/"><span className="menu-text">Business</span></a></li>
                                            <li><a href="/"><span className="menu-text">Artist <span className="badge primary">New</span></span></a></li>
                                            <li><a href="/"><span className="menu-text">Kitchen Coach <span className="badge primary">New</span></span></a></li>
                                            <li><a href="/"><span className="menu-text">Motivation <span className="badge primary">New</span></span></a></li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-50">
                                        <ul>
                                            <li><a href="/"><img src="assets/images/menu/mega-menu.jpg" alt="menu-imae"/></a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            
                            <li className="has-children">
                                <a href="/Courses"><span className="menu-text">Courses</span></a>
                                
                                
                            </li>
                            <li className="has-children">
                                <a href="/contact"><span className="menu-text">Contact-us</span></a>
                                <span className="menu-toggle"><i className="fas fa-angle-down"></i></span>
                                
                            </li>
                            <li className="has-children">
                                <a href="/Blog"><span className="menu-text">Blog</span></a>
                                
                                
                            </li>
                            <li className="has-children">
                                <a href="/Shop"><span className="menu-text">Shop</span></a>
                                
                            </li>
                        </ul>
                    </nav>
                </div>
                {/* Header Main Menu End */}

                {/* Header Right Start */}
                <div className="col-xl-3 col-auto">
                    <div className="header-right">
                        <div className="inner">

                            {/* Header Cart Start */}
                            <div className="header-cart">

                                <a className="header-cart-btn" href="/shopping-cart.html"><span className="cart-count">2</span><i className="fas fa-shopping-basket"></i></a>

                                {/* Header Mini Cart Start */}
                                <div className="header-mini-cart">
                                    <div className="inner">

                                        {/* Header Mini Cart Product Start */}
                                        {/* <div className="mini-cart-products">
                                            <div className="mini-cart-product">
                                                <a href="/#" className="thumb"><img src="assets/images/products/mini-cart/product-1.jpg" alt=""/></a>
                                                <div className="content">
                                                    <a href="/#" className="title">Online Student: Strategies for Effective Learning</a>
                                                    <span className="quantity">1 x <span className="price">$67.00</span></span>
                                                </div>
                                                <a href="/#" className="remove"><i className="fas fa-times"></i></a>
                                            </div>
                                            <div className="mini-cart-product">
                                                <a href="/#" className="thumb"><img src="assets/images/products/mini-cart/product-2.jpg" alt=""/></a>
                                                <div className="content">
                                                    <a href="/#" className="title">Principles of Business Administration</a>
                                                    <span className="quantity">1 x <span className="price">$52.00</span></span>
                                                </div>
                                                <a href="/#" className="remove"><i className="fas fa-times"></i></a>
                                            </div>
                                        </div> */}
                                        {/* Header Mini Cart Product End */}

                                        {/* Header Mini Cart Footer Start */}
                                        {/* <div className="mini-cart-footer">
                                            <div className="mini-cart-total">
                                                <b>Total:</b>
                                                <span className="amount">$119.00</span>
                                            </div>
                                            <div className="mini-cart-buttons">
                                                <a href="/shopping-cart.html" className="btn btn-primary btn-hover-secondary">View Cart</a>
                                                <a href="/checkout.html" className="btn btn-primary btn-hover-secondary">Checkout</a>
                                            </div>
                                        </div> */}
                                        {/* Header Mini Cart Footer End */}
                                    </div>
                                </div>
                                {/* Header Mini Cart End */}
                            </div>
                            {/* Header Cart End */}

                            {/* Header Login Start */}
                            <div className="header-login">
                                <a href="/"><i className="far fa-user-circle"></i></a>
                            </div>
                            {/* Header Login End */}

                            {/* Header Search Start */}
                            <div className="header-search">
                                <button className="header-search-toggle"><i className="fas fa-search"></i></button>
                                <div className="header-search-form">
                                    <form action="#">
                                        <input 
                                        type="text" 
                                        placeholder="Search..." 
                                        value={wordEntered}
                                        onChange={handleFilter}/>
         
                                        <button><i className="fas fa-search"></i></button>
                                    </form>
                                    <div className='mobile-search'>
                                {filteredData.length != 0 && (
                                        <div className="dataResult" 
                                        style={{
                                            position: "absolute",
                                            width: "90%",
                                            height: "fit-content",
                                            padding:"10px",
                                            background: "rgba(12, 13, 14, 0.8)",
                                            zIndex: 1001,
                                            color: "white",
                                            display: "flex",
                                            flexDirection: "column",
                                            borderRadius:"15px",
                                            paddingLeft:"auto",
                                            paddingRight:"auto",
                                            justifyContent:'center'
                                        
                                        }}>
                                        {filteredData.slice(0, 15).map((value, key) => {
                                            return (
                                            <a className="dataItem" href={`/blog/${value.id}`}>
                                                <p>{value.title} </p>
                                            </a>
                                            );
                                        })}
                                        </div>
                                    )}
                                </div>
                                </div>
                                
                                
                            </div>
                            
                            {/* Header Search End */}


                            {/* Header Mobile Menu Toggle Start */}
                            <div className="header-mobile-menu-toggle d-xl-none ml-sm-2">
                                <button className="toggle">
                                    <i className="icon-top"></i>
                                    <i className="icon-middle"></i>
                                    <i className="icon-bottom"></i>
                                </button>
                            </div>
                            {/* Header Mobile Menu Toggle End */}
                        </div>
                    </div>
                </div>
                {/* Header Right End */}
                <div  className='searchcomponent' style={{
                                position: "sticky",
                                width: "100vw",
                                height: "fit-content",
                                
                                zIndex: 100,
                                color: "white",
                                fontWeight:400,
                                textAlign: 'center'
                        }}>
                                {filteredData.length != 0 && (
                                <div className="dataResult" style={{
                                    position: "absolute",
                                    width: "fit-content",
                                    height: "fit-content",
                                    padding:"10px",
                                    background: "rgba(12, 13, 14, 0.8)",
                                    zIndex: 1001,
                                    color: "white",
                                    display: "flex",
                                    flexDirection: "row",
                                    borderRadius:"15px",
                                    paddingLeft:"auto",
                                    paddingRight:"auto",
                                    alignItems: "center"
                                
                                }} data-aos="fade-left" >
                                
                            {filteredData.slice(0, 8).map((value, key) => {
                            return (
                                <a className="dataItem" href={`/blog/${value.id}`}  onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave} >
                                        
                                        <div style={{width:"100px",padding:"0, 10px", backgroundImage: `url(${value.Bthumbnail})`, height:"100px", backgroundSize:"cover", backgroundPosition:"center"}}  
                                        Key={value.id}>
                                            {isHovered && (
                                            <p style={{fontSize:12, backgroundColor:"rgba(23, 23, 23, .6)"}}>{value.title} </p> )}
                                        </div>
                                            </a>
                                            
                                );
                                    })}
                                    </div>
                                
                            )}   
                        </div> 
                        <div className='searchcomponents' style={{
                                position: "sticky",
                                width: "100vw",
                                height: "fit-content",
                                
                                zIndex: 100,
                                color: "white",
                        }}>
                                {filteredData.length != 0 && (
                                <div className="dataResult" style={{
                                    position: "absolute",
                                    width: "fit-content",
                                    height: "fit-content",
                                    padding:"10px",
                                    background: "rgba(12, 13, 14, 0.8)",
                                    zIndex: 1001,
                                    color: "white",
                                    display: "flex",
                                    flexDirection: "row",
                                    borderRadius:"15px",
                                    paddingLeft:"auto",
                                    paddingRight:"auto",
                                    alignItems: "center"
                                
                                }}>
                                
                            {filteredData.slice(0, 6).map((value, key) => {
                            return (
                                <a className="dataItem" href={`/blog/${value.id}`}  onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave} >
                                        
                                        <div style={{width:"100px",padding:"0, 10px", backgroundImage: `url(${value.Bthumbnail})`, height:"100px", backgroundSize:"cover", backgroundPosition:"center"}}  
                                        >
                                            {isHovered && (
                                            <p style={{fontSize:12, backgroundColor:"rgba(23, 23, 23, .6)"}}>{value.title} </p> )}
                                        </div>
                                            </a>
                                            
                                );
                                    })}
                                    </div>
                                
                            )}   
                        </div>

                       
       
            </div>
        </div>
    </div>
    
            
</div>

  )
}

export default Header