import React from 'react'

function Event() {
  return (
    <>

<div className="page-title-section section">
            <div className="page-title">
                <div className="container">
                    <h1 className="title">Events</h1>
                </div>
            </div>
            <div className="page-breadcrumb">
                <div className="container">
                    <ul className="breadcrumb">
                        <li><a href="/">Home</a></li>
                        <li className="current">Events</li>
                    </ul>
                </div>
            </div>
        </div>
        {/* Page Title Section End */}

        {/* Event Section Start */}
        <div className="section section-padding-bottom">
            <div className="container">

                {/* Event Top Bar Start */}
                <div className="row justify-content-between align-items-center max-mb-20">
                    <div className="col-sm-auto col-12 max-mb-10">
                        <p className="result-count">We found <span>14</span> events available for you</p>
                    </div>
                    <div className="col-sm-auto col-12 max-mb-10">
                        <select className="sort-by">
                            <option selected="selected">Default</option>
                            <option value="popularity">Popularity</option>
                            <option value="date">Latest</option>
                            <option value="price">Price: low to high</option>
                            <option value="price-desc">Price: high to low</option>
                        </select>
                    </div>
                </div>
                {/* Event Top Bar End */}

                {/* Event Wrapper Start */}
                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">

                    {/* Event Start */}
                    <div className="col max-mb-30" data-aos="fade-up">
                        <div className="event">
                            <div className="thumbnail">
                                <a href="event-details.html" className="image"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/event/370/event1.jpg" alt="Event Imae"/></a>
                                <div className="event-overlay-background"></div>
                                <div className="event-overlay-content">
                                    <a className="btn btn-md btn-light btn-hover-light theme-color" href="event-details.html">Get ticket</a>
                                </div>
                            </div>
                            <div className="info">
                                <span className="date">Nov 22, 2022</span>
                                <h3 className="title"><a href="event-details.html">Storytelling Workshop</a></h3>
                                <ul className="event-location">
                                    <li><i className="fas fa-map-marker-alt"></i>Texas, US</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Event End */}

                    {/* Event Start */}
                    <div className="col max-mb-30" data-aos="fade-up">
                        <div className="event">
                            <div className="thumbnail">
                                <a href="event-details.html" className="image"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/event/370/event2.jpg" alt="Event Imae"/></a>
                                <div className="event-overlay-background"></div>
                                <div className="event-overlay-content">
                                    <a className="btn btn-md btn-light btn-hover-light theme-color" href="event-details.html">Get ticket</a>
                                </div>
                            </div>
                            <div className="info">
                                <span className="date">Oct 10, 2022</span>
                                <h3 className="title"><a href="event-details.html">Painting Art Contest 2022</a></h3>
                                <ul className="event-location">
                                    <li><i className="fas fa-map-marker-alt"></i>New York, US</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Event End */}

                    {/* Event Start */}
                    <div className="col max-mb-30" data-aos="fade-up">
                        <div className="event">
                            <div className="thumbnail">
                                <a href="event-details.html" className="image"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/event/370/event3.jpg" alt="Event Imae"/></a>
                                <div className="event-overlay-background"></div>
                                <div className="event-overlay-content">
                                    <a className="btn btn-md btn-light btn-hover-light theme-color" href="event-details.html">Get ticket</a>
                                </div>
                            </div>
                            <div className="info">
                                <span className="date">Nov 23, 2022</span>
                                <h3 className="title"><a href="event-details.html">International Art Fair 2022</a></h3>
                                <ul className="event-location">
                                    <li><i className="fas fa-map-marker-alt"></i>Hamburg, Germany</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Event End */}

                    {/* Event Start */}
                    <div className="col max-mb-30" data-aos="fade-up">
                        <div className="event">
                            <div className="thumbnail">
                                <a href="event-details.html" className="image"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/event/370/event4.jpg" alt="Event Imae"/></a>
                                <div className="event-overlay-background"></div>
                                <div className="event-overlay-content">
                                    <a className="btn btn-md btn-light btn-hover-light theme-color" href="event-details.html">Get ticket</a>
                                </div>
                            </div>
                            <div className="info">
                                <span className="date">Dec 15, 2022</span>
                                <h3 className="title"><a href="event-details.html">Street Performance: Call for Artist</a></h3>
                                <ul className="event-location">
                                    <li><i className="fas fa-map-marker-alt"></i>Illinois, US</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Event End */}

                    {/* Event Start */}
                    <div className="col max-mb-30" data-aos="fade-up">
                        <div className="event">
                            <div className="thumbnail">
                                <a href="event-details.html" className="image"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/event/370/event5.jpg" alt="Event Imae"/></a>
                                <div className="event-overlay-background"></div>
                                <div className="event-overlay-content">
                                    <a className="btn btn-md btn-light btn-hover-light theme-color" href="event-details.html">Get ticket</a>
                                </div>
                            </div>
                            <div className="info">
                                <span className="date">Jul 22, 2022</span>
                                <h3 className="title"><a href="event-details.html">Consumer Food Safety Education Conference</a></h3>
                                <ul className="event-location">
                                    <li><i className="fas fa-map-marker-alt"></i>Illinois, US</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Event End */}

                    {/* Event Start */}
                    <div className="col max-mb-30" data-aos="fade-up">
                        <div className="event">
                            <div className="thumbnail">
                                <a href="event-details.html" className="image"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/event/370/event6.jpg" alt="Event Imae"/></a>
                                <div className="event-overlay-background"></div>
                                <div className="event-overlay-content">
                                    <a className="btn btn-md btn-light btn-hover-light theme-color" href="event-details.html">Get ticket</a>
                                </div>
                            </div>
                            <div className="info">
                                <span className="date">Aug 12, 2022</span>
                                <h3 className="title"><a href="event-details.html">How meditation improve your mental health?</a></h3>
                                <ul className="event-location">
                                    <li><i className="fas fa-map-marker-alt"></i>Dubai</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Event End */}

                    {/* Event Start */}
                    <div className="col max-mb-30" data-aos="fade-up">
                        <div className="event">
                            <div className="thumbnail">
                                <a href="event-details.html" className="image"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/event/370/event7.jpg" alt="Event Imae"/></a>
                                <div className="event-overlay-background"></div>
                                <div className="event-overlay-content">
                                    <a className="btn btn-md btn-light btn-hover-light theme-color" href="event-details.html">Get ticket</a>
                                </div>
                            </div>
                            <div className="info">
                                <span className="date">Sep 18, 2022</span>
                                <h3 className="title"><a href="event-details.html">Dialogue for Action on Cancer Screening and Prevention</a></h3>
                                <ul className="event-location">
                                    <li><i className="fas fa-map-marker-alt"></i>Washington DC, US</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Event End */}

                    {/* Event Start */}
                    <div className="col max-mb-30" data-aos="fade-up">
                        <div className="event">
                            <div className="thumbnail">
                                <a href="event-details.html" className="image"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/event/370/event8.jpg" alt="Event Imae"/></a>
                                <div className="event-overlay-background"></div>
                                <div className="event-overlay-content">
                                    <a className="btn btn-md btn-light btn-hover-light theme-color" href="event-details.html">Get ticket</a>
                                </div>
                            </div>
                            <div className="info">
                                <span className="date">Sep 24, 2022</span>
                                <h3 className="title"><a href="event-details.html">Workout routine and Longevity Meetup</a></h3>
                                <ul className="event-location">
                                    <li><i className="fas fa-map-marker-alt"></i>Hamburg, Germany</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Event End */}

                    {/* Event Start */}
                    <div className="col max-mb-30" data-aos="fade-up">
                        <div className="event">
                            <div className="thumbnail">
                                <a href="/" className="image"><img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/event/370/event9.jpg" alt="Event Imae"/></a>
                                <div className="event-overlay-background"></div>
                                <div className="event-overlay-content">
                                    <a className="btn btn-md btn-light btn-hover-light theme-color" href="event-details.html">Get ticket</a>
                                </div>
                            </div>
                            <div className="info">
                                <span className="date">Jul 29, 2023</span>
                                <h3 className="title"><a href="event-details.html">Learning Counsel Digital Transition Discussion Event – Chicago</a></h3>
                                <ul className="event-location">
                                    <li><i className="fas fa-map-marker-alt"></i>Chicago, US</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Event End */}

                </div>
                {/* Event Wrapper End */}

                {/* Pagination Start */}
                <div className="row max-mt-50">
                    <div className="col">

                        <ul className="pagination center">
                            <li><a href="/" className="prev">PREV</a></li>
                            <li><a href="/" className="active">1</a></li>
                            <li><a href="/">2</a></li>
                            <li><a href="/" className="next">NEXT</a></li>
                        </ul>

                    </div>
                </div>
                {/* Pagination End */}

            </div>
        </div>

    </>
  )
}

export default Event