import React from 'react'

function Mobilemenu() {
  return (
    <div id="site-main-mobile-menu" className="site-main-mobile-menu">
        <div className="site-main-mobile-menu-inner">
            <div className="mobile-menu-header">
                <div className="mobile-menu-logo">
                    <a href="/"><img src="assets/images/logo/dark-logo.png" alt=""/></a>
                </div>
                <div className="mobile-menu-close">
                    <button className="toggle">
                        <i className="icon-top"></i>
                        <i className="icon-bottom"></i>
                    </button>
                </div>
            </div>
            <div className="mobile-menu-content">
                <nav className="site-mobile-menu">
                    <ul>
                        <li className="has-children position-static">
                            <a href="/"><span className="menu-text">Home</span></a>
                            <span className="menu-toggle"><i className="fas fa-angle-down"></i></span>

                            <ul className="mega-menu">
                                <li>
                                    <ul>
                                        <li><a href="/"><span className="menu-text">Obeddev Education <span className="badge">Hot</span></span></a></li>
                                        <li><a href="/"><span className="menu-text">Course Portal</span></a></li>
                                        <li><a href="/"><span className="menu-text">Distant Learning <span className="badge">Hot</span></span></a></li>
                                        <li><a href="/"><span className="menu-text">Multimedia Pedagogy</span></a></li>
                                        <li><a href="/"><span className="menu-text">Modern Schooling</span></a></li>
                                        <li><a href="/index-6.html"><span className="menu-text">Remote Training</span></a></li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li><a href="/"><span className="menu-text">Health Coaching</span></a></li>
                                        <li><a href="/"><span className="menu-text">Gym Coaching</span></a></li>
                                        <li><a href="/"><span className="menu-text">Business</span></a></li>
                                        <li><a href="/"><span className="menu-text">Artist <span className="badge primary">New</span></span></a></li>
                                        <li><a href="/"><span className="menu-text">Kitchen Coach <span className="badge primary">New</span></span></a></li>
                                        <li><a href="/"><span className="menu-text">Motivation <span className="badge primary">New</span></span></a></li>
                                    </ul>
                                </li>
                                <li className="menu-item-50">
                            
                                    <ul>
                                        <li><a href="/"><img src="assets/images/menu/mega-menu.jpg" alt="menu-imae"/></a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        
                        <li className="has-children">
                            <a href="/Courses"><span className="menu-text">Courses</span></a>
                           </li>
                        <li className="has-children">
                            <a href="/contact"><span className="menu-text">Contact-us</span></a>
                            <span className="menu-toggle"><i className="fas fa-angle-down"></i></span>
                            
                        </li>
                        <li className="has-children">
                            <a href="/Blog"><span className="menu-text">Blog</span></a>
                           
                        </li>
                        <li className="has-children">
                            <a href="/Shop"><span className="menu-text">Shop</span></a>
                            
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>

  )
}

export default Mobilemenu