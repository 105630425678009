import React from 'react'
import courses from "../components/course.json"
import { Link  } from 'react-router-dom'
function Courses() {
    
    const handleProductClick = (productId) => {
        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    
        // Additional logic for handling the product click can go here
        console.log(`Product ${productId} clicked`);
      };
    
  return (
    <>
      
      <div className="page-title-section section">
            {/* <div className="page-title">
                <div className="container">
                    <h1 className="title">Courses</h1>
                </div>
            </div> */}
            <div className="page-breadcrumb">
                <div className="container">
                    <ul className="breadcrumb">
                        <li><a href="/">Home</a></li>
                        <li className="current">Courses</li>
                    </ul>
                </div>
            </div>
        </div>
        {/* Page Title Section End */}

        {/* Course Section Start */}
        <div className="section section-padding-bottom">
            <div className="container">

                {/* Course Top Bar Start */}
                <div className="row justify-content-between align-items-center max-mb-20">
                    <div className="col-sm-auto col-12 max-mb-10">
                        <p className="result-count">We found <span>22</span> courses available for you</p>
                    </div>
                    <div className="col-sm-auto col-12 max-mb-10">
                        <select className="sort-by">
                            <option selected="selected">Default</option>
                            <option value="popularity">Popularity</option>
                            <option value="date">Latest</option>
                            <option value="price">Price: low to high</option>
                            <option value="price-desc">Price: high to low</option>
                        </select>
                    </div>
                </div>
                {/* Course Top Bar End */}

                {/* Courses Wrapper Start */}
                <div className="row row-cols-lg-2 row-cols-1 max-mb-n30" >
                { courses.map((course) => {
                    return (
                        <div className="col max-mb-30" data-aos="fade-up" key={course.id} onClick={handleProductClick}>
                    <div className="course-3"  >
                        <div className="thumbnail">
                          <Link to={`/courses/course/${course.id}`} ><a href={`/courses/course/${course.title}`} className="image"><img src={course.image} alt="Course Imae"/></a> </Link> 
                        </div>
                        <div className="info">
                            <span className="price">{course.price}<span>{course.priceI}</span></span>
                             <h3 className="title"><Link to={`/courses/course/${course.id}`} ><a href={`/courses/course/${course.title}`}>{course.title}</a></Link></h3>
                            <ul className="meta">
                                <li><i className="fas fa-file-alt"></i>{course.Nlessons} Lessons</li>
                                <li><i className="fas fa-user-alt"></i>{course.Nstudents} Students</li>
                            </ul>
                        </div>
                    </div>
                    </div>

                    )
                })}
                
                </div>
                {/* Courses Wrapper End */}

                <div className="row max-mt-50">
                    <div className="col text-center">
                        <a href="/#" className="btn btn-outline-primary load-more-btn">Load More <i className="fa fa-redo ms-3"></i></a>
                    </div>
                </div>

            </div>
        </div>

    </>
  )
}

export default Courses