import React from 'react'
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import Header from './Header'
import Hompage from './Hompage'
import Footer from './Footer'
import Mobilemenu from './Mobilemenu'
// import Scripts from './Scripts'
import Blog from './pages/Blog'
import Event from './pages/Event'
import Courses from './pages/Courses' 
import Membership from './pages/Membership' 
import About from './pages/About' 
import Contact from './pages/Contact'
import Shop from './pages/Shop' 
import ProductDetail from './pages/ProductDetail'
import PostDetails from './pages/PostDetails'
import CourseDetails from './pages/CourseDetails'
import Success from './pages/Success'
import StartHere from './pages/StartHere'


function App() {
  
  return (
    <>
    
    <div id="page" className="section">
            
            <Header />
            
            <Router> 
            <Routes> 
              
              <Route exact path="/" element={<Hompage />} />
                <Route path="/Home" element={<Hompage />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/starthere" element={<StartHere />} />
                <Route path="/success" element={<Success/>} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/Membership" element={<Membership />} />
                <Route path="/Shop" element={<Shop />} />
                <Route path="/Event" element={<Event />} />
                <Route path="/Courses" element={<Courses />} />
                <Route path="/About" element={<About />} />
                <Route path="/blog/:postId" element={<PostDetails />} />
                <Route path="/courses/course/:courseId" element={<CourseDetails />} />
                <Route path="/courses/course/" element={<CourseDetails />} />
                <Route path="/shop/product/:productId" element={<ProductDetail />} /> 
                
                

                {/* <Route path="*" element={<NoPage />} /> */}
              
              
            </Routes>
            </Router>
            {/* <Scripts />  */}
    </div>
            <Mobilemenu />
            <Footer />
            
    </>
            
             
            

    
    
  )
}

export default App