import React from 'react'

function StartHere() {
  return (
    <>
    
    <div className="page-banner-slider section">
            <div className="banner-slider swiper-container">

                <div className="swiper-wrapper">

                    <div className="swiper-slide">
                       {/*Banner Slider Item start*/}
                        <div className="banner-slider-item" data-bg-image="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/page-banner/start-here-hero-slide-01-bg.jpg">
                            <div className="container">
                                <div className="row row-25 banner-content-area">
                                    <div className="col-lg-4">
                                        <div className="banner-slider-content-2 right">
                                            <h1>AWAKEN <br/> YOUR GIFTS <br/>NOW </h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">

                                       {/*Banner Slider Content start*/}
                                        <div className="banner-slider-content-2 left">
                                            <h3>
                                                <span className="number">01</span>
                                                <span className="line"></span>
                                                INSPIRING CURRICULUM
                                            </h3>
                                            <p>Learning with Obeddev might a turning point in your life when you get communication with a great awakening of born talents.</p>
                                        </div>
                                       {/*Banner Slider Content end*/}

                                    </div>
                                </div>
                            </div>
                        </div>
                       {/*Banner Slider Item end*/}
                    </div>

                    <div className="swiper-slide">
                       {/*Banner Slider Item start*/}
                        <div className="banner-slider-item" data-bg-image="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/page-banner/start-here-hero-slide-02-bg.jpg">
                            <div className="container">
                                <div className="row row-25 banner-content-area">
                                    <div className="col-lg-4">
                                        <div className="banner-slider-content-2 right">
                                            <h1>OPEN UP <br/>  A BRIGHT <br/>SKY </h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">

                                       {/*Banner Slider Content start*/}
                                        <div className="banner-slider-content-2 left">
                                            <h3>
                                                <span className="number">02</span>
                                                <span className="line"></span>
                                                life consultancy
                                            </h3>
                                            <p>Ever feel drained of ideas and inspiration in life? Lost in no where and have no place to go? Find help and advice from our MaxCoach specialists.</p>
                                        </div>
                                       {/*Banner Slider Content end*/}

                                    </div>
                                </div>
                            </div>
                        </div>
                       {/*Banner Slider Item end*/}
                    </div>

                    <div className="swiper-slide">
                       {/*Banner Slider Item start*/}
                        <div className="banner-slider-item" data-bg-image="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/page-banner/start-here-hero-slide-03-bg.jpg">
                            <div className="container">
                                <div className="row row-25 banner-content-area">
                                    <div className="col-lg-4">
                                        <div className="banner-slider-content-2 right">
                                            <h1>write your <br/>   own life <br/>book </h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">

                                       {/*Banner Slider Content start*/}
                                        <div className="banner-slider-content-2 left">
                                            <h3>
                                                <span className="number">03</span>
                                                <span className="line"></span>
                                                skill advancement
                                            </h3>
                                            <p>Not only your skills are advanced, your inner self can get a new identity as well. You're the one who take the full control of your life book.</p>
                                        </div>
                                       {/*Banner Slider Content end*/}

                                    </div>
                                </div>
                            </div>
                        </div>
                       {/*Banner Slider Item end*/}
                    </div>

                </div>

                <div className="banner-slider-prev swiper-button-prev banner-slider-nav"><i className="fas fa-angle-left"></i></div>
                <div className="banner-slider-next swiper-button-next banner-slider-nav"><i className="fas fa-angle-right"></i></div>

            </div>
        </div>
       {/* Page Banner Slider End */}

       {/* Success Section Start  */}
        <div className="success-section section section-padding">
            <div className="container">
               {/* Section Title Start */}
                <div className="section-title text-center" data-aos="fade-up">
                    <span className="sub-title secondary-color">Four major Steps to Success</span>
                    <h2 className="title heading-color">Best chances for expanding your <br/> success to the max</h2>
                </div>
               {/* Section Title End */}

                <div className="row">
                    <div className="col-lg-12">
                        <div className="mx-timeline">
                            <ul className="mx-timeline-list mx-animation-queue">
                                <li className="line"></li>
                                <li className="item animate">
                                    <div className="dots">
                                        <div className="middle-dot"></div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 timeline-col left timeline-feature">
                                            <div className="inner">
                                                <div className="title-wrap">
                                                    <h5 className="title">Broadening</h5>
                                                </div>

                                                <div className="timeline-image">
                                                    <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/timeline/start-here-timeline-photo-01.jpg" alt="timeline-image-01" className="img-fluid"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 timeline-col right timeline-info">
                                            <div className="inner">
                                                <div className="timeline-content-wrap">
                                                    <div className="timeline-content-body">
                                                        <h4 className="title">#1 Career advancement and hobbies</h4>

                                                        <p>
                                                            Studying online gives you more flexibility. You can work and fit your work schedule around your coursework more easily; even more so if you are taking an asynchronous class: an online class where you can study and interact with your instructor and fellow classmates at your own pace. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="item animate">
                                    <div className="dots">
                                        <div className="middle-dot"></div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 timeline-col left timeline-feature">
                                            <div className="inner">
                                                <div className="title-wrap">
                                                    <h5 className="title"> Scheduling </h5>
                                                </div>

                                                <div className="timeline-image">
                                                    <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/timeline/start-here-timeline-photo-02.jpg" alt="timeline-image-02" className="img-fluid"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 timeline-col right timeline-info">
                                            <div className="inner">
                                                <div className="timeline-content-wrap">
                                                    <div className="timeline-content-body">
                                                        <h4 className="title">#2 Flexible schedule & environment</h4>

                                                        <p>Taking an online course also means that you don’t have to commute to class, which means less time spent on the bus and more study time sitting on your couch, the sound of a crackling fireplace in the background. You no longer have to worry about missing an important class!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="item animate">
                                    <div className="dots">
                                        <div className="middle-dot"></div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 timeline-col left timeline-feature">
                                            <div className="inner">
                                                <div className="title-wrap">
                                                    <h5 className="title"> Self-discipline </h5>
                                                </div>

                                                <div className="timeline-image">
                                                    <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/timeline/start-here-timeline-photo-03.jpg" alt="timeline-image-03" className="img-fluid"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 timeline-col right timeline-info">
                                            <div className="inner">
                                                <div className="timeline-content-wrap">
                                                    <div className="timeline-content-body">
                                                        <h4 className="title">#3 Self-discipline & responsibility</h4>

                                                        <p>Who says that having to be more self-disciplined is a disadvantage? It is true that studying online requires more time-management skills, because you will spend a lot of time on your own without someone physically close to keep you focused on deadlines.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="item animate">
                                    <div className="dots">
                                        <div className="middle-dot"></div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 timeline-col left timeline-feature">
                                            <div className="inner">
                                                <div className="title-wrap">
                                                    <h5 className="title"> Investment </h5>
                                                </div>

                                                <div className="timeline-image">
                                                    <img src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/timeline/start-here-timeline-photo-04.jpg" alt="timeline-image-04" className="img-fluid"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 timeline-col right timeline-info">
                                            <div className="inner">
                                                <div className="timeline-content-wrap">
                                                    <div className="timeline-content-body">
                                                        <h4 className="title">#4 Investment</h4>

                                                        <p>Studying online means that you pay the tuition fee, possibly book supplies, an online application fee, and few other items. You don’t, however, incur the costs of housing (which can range up to $12,000 per year) and transportation, which translates to lower debts and more savings.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       {/* Success Section End  */}

       {/* CTA Section Start */}
        <div className="cta-section section section-padding-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="cta-content-area">
                           {/* Section Title Start */}
                            <div className="section-title text-center mb-0" data-aos="fade-up">
                                <span className="sub-title secondary-color max-mb-30">Get yourself a <strong>Obeddev</strong>  ID?</span>
                                <h2 className="title heading-color max-mb-30">Take your interest and register an online class <br/> today to enjoy life fully</h2>
                                <a href="/#" className="btn btn-primary btn-hover-secondary btn-width-300"> Get started for free</a>
                            </div>
                           {/* Section Title End */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
       {/* CTA Section End */}

       {/* Gallery Section Start */}
        <div className="gallery-section section">
            <div className="container-fluid p-0">
                <div className="justified-gallery">

                    <div className="grid-item">
                       {/* Single Gallery Start */}
                        <a href="success-story.html" className="single-gallery">
                            <div className="thumbnail">
                                <img className="img-fluid" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/gallery/start-here-gallery-image-01.jpg" alt="gallery-01"/>
                            </div>
                            <div className="content">
                                <h5 className="title">Lonnie Marks</h5>
                                <div className="description">
                                    <p>Tutor</p>
                                </div>
                            </div>
                        </a>
                       {/* Single Gallery End */}
                    </div>

                    <div className="grid-item">
                       {/* Single Gallery Start */}
                        <a href="success-story.html" className="single-gallery">
                            <div className="thumbnail">
                                <img className="img-fluid" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/gallery/start-here-gallery-image-02.jpg" alt="gallery-02"/>
                            </div>
                            <div className="content">
                                <h5 className="title">Lonnie Marks</h5>
                                <div className="description">
                                    <p>Tutor</p>
                                </div>
                            </div>
                        </a>
                       {/* Single Gallery End */}
                    </div>

                    <div className="grid-item">
                       {/* Single Gallery Start */}
                        <a href="success-story.html" className="single-gallery">
                            <div className="thumbnail">
                                <img className="img-fluid" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/gallery/start-here-gallery-image-03.jpg" alt="gallery-03"/>
                            </div>
                            <div className="content">
                                <h5 className="title">Lonnie Marks</h5>
                                <div className="description">
                                    <p>Tutor</p>
                                </div>
                            </div>
                        </a>
                       {/* Single Gallery End */}
                    </div>

                    <div className="grid-item">
                       {/* Single Gallery Start */}
                        <a href="success-story.html" className="single-gallery">
                            <div className="thumbnail">
                                <img className="img-fluid" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/gallery/start-here-gallery-image-04.jpg" alt="gallery-04"/>
                            </div>
                            <div className="content">
                                <h5 className="title">Lonnie Marks</h5>
                                <div className="description">
                                    <p>Tutor</p>
                                </div>
                            </div>
                        </a>
                       {/* Single Gallery End */}
                    </div>

                    <div className="grid-item">
                       {/* Single Gallery Start */}
                        <a href="success-story.html" className="single-gallery">
                            <div className="thumbnail">
                                <img className="img-fluid" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/gallery/start-here-gallery-image-05.jpg" alt="gallery-05"/>
                            </div>
                            <div className="content">
                                <h5 className="title">Lonnie Marks</h5>
                                <div className="description">
                                    <p>Tutor</p>
                                </div>
                            </div>
                        </a>
                       {/* Single Gallery End */}
                    </div>

                    <div className="grid-item">
                       {/* Single Gallery Start */}
                        <a href="success-story.html" className="single-gallery">
                            <div className="thumbnail">
                                <img className="img-fluid" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/gallery/start-here-gallery-image-06.jpg" alt="gallery-06"/>
                            </div>
                            <div className="content">
                                <h5 className="title">Lonnie Marks</h5>
                                <div className="description">
                                    <p>Tutor</p>
                                </div>
                            </div>
                        </a>
                       {/* Single Gallery End */}
                    </div>

                    <div className="grid-item">
                       {/* Single Gallery Start */}
                        <a href="success-story.html" className="single-gallery">
                            <div className="thumbnail">
                                <img className="img-fluid" src="https://htmldemo.net/maxcoach-v1/maxcoach/assets/images/gallery/start-here-gallery-image-07.jpg" alt="gallery-07"/>
                            </div>
                            <div className="content">
                                <h5 className="title">Lonnie Marks</h5>
                                <div className="description">
                                    <p>Tutor</p>
                                </div>
                            </div>
                        </a>
                       {/* Single Gallery End */}
                    </div>

                </div>
            </div>
        </div>
       {/* Gallery Section End */}

       {/* Faq Section Start */}
        <div className="faq-section section section-padding">
            <div className="container">
               {/* Section Title Start */}
                <div className="section-title text-center" data-aos="fade-up">
                    <span className="sub-title secondary-color">Four major Steps to Success</span>
                    <h2 className="title heading-color">Best chances for expanding your <br/> success to the max</h2>
                </div>
               {/* Section Title End */}
                <div className="row">
                   {/* Start Single Accordion */}
                    <div className="col-lg-6">
                        <div className="mx-accordion max-mb-n30 mb-sm-30 mb-xs-30" id="accordionExample">
                            <div className="card">
                                <div className="card-header" id="headingOne">
                                    <h5 className="mb-0">
                                        <a href="#" className="acc-btn" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            How to register online?
                                        </a>
                                    </h5>
                                </div>

                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="card-body">Enrolling in an online class is easy. At Obeddev, we serve several categories of online learners. Select the student category that you identify with to get started.
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                        <a href="#" className="acc-btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            How do I customize my account?
                                        </a>
                                    </h5>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="card-body">In order to customize your account’s information, just log in with the provided passcode and change the info.
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingThree">
                                    <h5 className="mb-0">
                                        <a href="#" className="acc-btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            How do you process my information?
                                        </a>
                                    </h5>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="card-body">Your data will be kept private and secure on our database. No illegal use without prior notice.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                   {/* End Single Accordion */}

                   {/* Start Single Accordion */}
                    <div className="col-lg-6">
                        <div className="mx-accordion max-mb-n30" id="accordionExample2">
                            <div className="card">
                                <div className="card-header" id="headingfour">
                                    <h5 className="mb-0">
                                        <a href="#" className="acc-btn" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                            How do I contact support?
                                        </a>
                                    </h5>
                                </div>

                                <div id="collapsefour" className="collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample2">
                                    <div className="card-body">You can contact our help center or any contact method (phone call, email or appear at our office) to get help. </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingfive">
                                    <h5 className="mb-0">
                                        <a href="#" className="acc-btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                                            How to cancel my membership?
                                        </a>
                                    </h5>
                                </div>
                                <div id="collapsefive" className="collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExample2">
                                    <div className="card-body">You just need to submit a membership termination request, we’ll proceed within 24 hours.
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingsix">
                                    <h5 className="mb-0">
                                        <a href="#" className="acc-btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                            How can I get a discount?
                                        </a>
                                    </h5>
                                </div>
                                <div id="collapsesix" className="collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample2">
                                    <div className="card-body">Learners can get a discount by coupon or voucher. If you have one, fill in the code box before making any payment.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                   {/* End Single Accordion */}
                </div>
            </div>
        </div>
       {/* Faq Section End */}
    
    </>
  )
}

export default StartHere